import { useQueryClient } from 'react-query';
import axios from 'axios';
import { API_URL } from 'api/client';
import { useNavigate } from 'react-router-dom';

export const useCreateNewChat = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  
  const createNewChat = async (token: string): Promise<string | null> => {
    try {
      const response = await axios.post(
        `${API_URL}/create-session`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const newSession = {
        id: response.data.session_id,
        created_at: new Date().toISOString(),
        first_message: 'New chat',
      };

      // Update cache with new session
      queryClient.setQueryData('sessions', (oldData: any) => {
        if (!oldData || !oldData.pages) {
          return { pages: [{ history: [newSession] }] };
        }

        const newPages = oldData.pages.map(({ page, index }: any) => {
          if (index === 0) {
            return {
              ...page,
              history: [newSession, ...page.history],
            };
          }
          return page;
        });

        return {
          ...oldData,
          pages: newPages,
        };
      });

      // Invalidate queries to refetch data from the server
      queryClient.invalidateQueries('chat-sessions');


      return response.data.session_id;
    } catch (error: any) {
      console.error('Error creating new chat session:', error);
     
      return null;
    }
  };

  return { createNewChat };
};
