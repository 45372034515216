import React, { useState, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import { API_URL } from 'api/client';
import { IUser } from './Interfaces';
import {
  Container,
  Button,
  CardContent,
  Typography,
  Grid,
  TextField,
  Card,
  Box,
  Avatar,
} from '@mui/material';
import { useLanguage } from 'contexts/LanguageContext';
import { labels, Language } from '../Authentication/languageLabels'; // Adjust the import path
import companyNameSlogan from '../../assets/images/company-name-slogan.png';
import DummyAvatar from 'assets/images/dummy-avatar.png';
import { useNavigate } from 'react-router-dom';

function MyAccount() {
  const [avatar, setAvatar] = useState<string | null>(null);
  const [userData, setUserData] = useState<IUser | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const auth = useAuth();
  const userJson = localStorage.getItem('user');
  const token = userJson ? JSON.parse(userJson).access_token : null;

  const { language, setLanguage } = useLanguage(); // Get language and setLanguage from context

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value as Language);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log('Selected file:', file); // 
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await axios.post(
          `${API_URL}/update-avatar`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('Response data:', response.data);
        setAvatar(response.data.avatar_url);
        setUserData(prevData => {
          if (prevData) {
            return { ...prevData, avatar_url: response.data.avatar_url };
          }
          return prevData;
        });
      } catch (error) {
        console.error('Error updating avatar:', error);
        alert(labels[language].updateAvatarFailed); // Use labels for localization
      }
    }
  };

  const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert(labels[language].passwordMismatch); // Use labels for localization
      return;
    }
    try {
      await axios.post(
        `${API_URL}/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      alert(labels[language].passwordChangeSuccess); // Use labels for localization
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error: any) {
      console.error('Error changing password:', error);
      alert(labels[language].passwordChangeFailed + (error.response?.data?.detail || error.message)); // Use labels for localization
    }
  };

  if (!userData) {
    return <Typography>{labels[language].loading}</Typography>; // Use labels for localization
  }
  let accountPackage;

  if (userData.level === 0) {
    accountPackage = labels[language].free;
  } else {
    accountPackage = labels[language].paid;
  }

  return (
    <Container maxWidth="md" sx={{ position: 'relative', background: 'var(--border-right-left-bar)', border: '2.5px solid var(--General-Stroke)' }}>
      {/* Thêm nút Quay lại ở đầu trang */}
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          mb: 2,
          background: 'var(--Color-Style-Primary-Gradient-Primary)',
          '&:hover': {
            background: 'var(--Color-Style-Primary-Gradient-Primary-hover)',
          },
        }}
      >
        {labels[language].backToChatbot}
      </Button>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <img
          src={companyNameSlogan}
          alt="company-name-slogan"
          style={{ height: '40px', gap: '10px' }}
        />
        <select
          value={language}
          onChange={onLanguageChange}
          style={{ marginLeft: 'auto', padding: '0.5rem', border: '1px solid #ccc' }}
        >
          <option value="en">English</option>
          <option value="vi">Tiếng Việt</option>
        </select>
      </Box>
      <Typography variant="h4" gutterBottom>
        {labels[language].myAccount} {/* Use labels for localization */}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {labels[language].profilePicture} {/* Use labels for localization */}
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  src={avatar || userData.avatar_url || DummyAvatar}
                  alt="Avatar"
                  sx={{ width: 100, height: 100, mb: 2 }}
                />
                {/* <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleAvatarChange}
                /> */}
                <label htmlFor="avatar-upload">
                  <Button variant="contained" component="span" disabled sx={{background : 'var(--Color-Style-Primary-Gradient-Primary)'}}>
                    {labels[language].uploadNewPicture} {/* Use labels for localization */}
                  </Button>
                </label>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {labels[language].userInformation} {/* Use labels for localization */}
              </Typography>
              <Typography>
                <strong>{labels[language].name}:</strong> {userData.full_name}
              </Typography>
              <Typography>
                <strong>{labels[language].email}:</strong> {userData.email}
              </Typography>
              <Typography>
                <strong>{labels[language].username}:</strong> {userData.username}
              </Typography>
              <Typography>
                <strong>{labels[language].phoneNumber}:</strong> {userData.phone_number}
              </Typography>
              <Typography>
                <strong>{labels[language].userLevel}:</strong> {userData.level}
              </Typography>
              <Typography>
                <strong>{labels[language].accountPackage}:</strong> {accountPackage}
              </Typography>
              <Typography>
                <strong>{labels[language].messageLimit}:</strong> {userData.message_limit}
              </Typography>
              <Typography>
                <strong>{labels[language].messagesToday}:</strong> {userData.message_count_today}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {labels[language].changePassword} {/* Use labels for localization */}
              </Typography>
              <form onSubmit={handleChangePassword}>
                <TextField
                  fullWidth
                  margin="normal"
                  label={labels[language].currentPassword} // Use labels for localization
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label={labels[language].newPassword} // Use labels for localization
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label={labels[language].confirmNewPassword} // Use labels for localization
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
                <Button
  type="submit"
  variant="contained"
  color="primary"
  sx={{
    background: 'var(--Color-Style-Primary-Gradient-Primary)',
    '&:hover': {
      background: 'var(--Color-Style-Primary-Gradient-Primary-hover)', // Replace with your desired hover color
    },
  }}
>
  {labels[language].changePassword} {/* Use labels for localization */}
</Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MyAccount;
