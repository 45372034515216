import React from 'react';
import { UserProvider } from 'contexts/UserContext';
import ProtectedRoutes from 'routes/ProtectedRoutes';

const AuthenticatedApp: React.FC = () => {
  return (
    <UserProvider>
      <ProtectedRoutes />
    </UserProvider>
  );
};

export default AuthenticatedApp;
