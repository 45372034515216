import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../api/client';
import { RECAPTCHA_SITE_KEY } from '../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Box, Typography, TextField, Link, Container, IconButton, InputAdornment, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLanguage } from 'contexts/LanguageContext';
import { labels } from './languageLabels';
import './Register.scss';
import FormContainer from 'components/Form/FormContainer';
import { registerUser } from 'services/api';
interface FormErrors {
  username?: string;
  password?: string;
  email?: string;
  fullName?: string;
  phoneNumber?: string;
}

function Register() {
  const { language } = useLanguage();
  const currentLabels = labels[language];

  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    email: '',
    fullName: '',
    phoneNumber: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (value.trim() !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${currentLabels[name as keyof typeof currentLabels]} ${currentLabels.isRequired}`,
      }));
    }
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key as keyof typeof formValues].trim()) {
        newErrors[key as keyof FormErrors] = `${currentLabels[key as keyof typeof currentLabels]} ${currentLabels.isRequired}`;
      }
    });

    if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = currentLabels.invalidEmail;
    }

    if (formValues.password.length < 8) {
      newErrors.password = currentLabels.passwordLength;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (!recaptchaToken) {
      setRegistrationStatus({ type: 'error', message: currentLabels.completeRecaptcha });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await registerUser({
        username: formValues.username,
        email: formValues.email,
        password: formValues.password,
        full_name: formValues.fullName,
        phone_number: formValues.phoneNumber,
        recaptcha_token: recaptchaToken,
      });
      setRegistrationStatus({ type: 'success', message: currentLabels.registrationSuccess });
      setTimeout(() => {
        navigate('/success-register', { state: { email: formValues.email } });
      }, 2000);
    } catch (error: any) {
      if (error.response && error.response.status === 400 && error.response.data.detail === "Username already exists") {
        setRegistrationStatus({
          type: 'error',
          message: currentLabels.usernameExists || 'Username already exists',
        });
      } else {
        setRegistrationStatus({
          type: 'error',
          message: error.message || currentLabels.registrationFailed,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };



  return (
    <FormContainer>
      <Container component="main" maxWidth="xs">
        <Box>
          <Typography component="h1" variant="h5" sx={{
            color: 'var(--Text-text-primary)',
            textAlign: 'center',
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Roboto',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: '2px',
          }}>
            {currentLabels.signUp}
          </Typography>
          {registrationStatus && (
            <Alert severity={registrationStatus.type} sx={{ marginBottom: '20px' }}>
              {registrationStatus.message}
            </Alert>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            className="register-form"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
              mt: 3,
            }}
          >
            <TextField
              required
              fullWidth
              id="username"
              label={currentLabels.username}
              name="username"
              value={formValues.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.username}
              helperText={errors.username}
              // className="input-username-field"
              sx={{
                '& .MuiInputLabel-root': {
                  color: 'var(--primary-text-color)', /* Màu của label khi không có giá trị nhập vào */
                },
                '& .MuiInputBase-root': {
                  backgroundColor: 'transparent !important',
                  borderRadius: '8px',
                  color: 'var(--primary-text-color) !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--General-Stroke) !important',
                  '&:hover': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '&:focus-within': {
                    borderColor: 'var(--General-Stroke) !important',
                  },

                },
              }}
            />
            <TextField
              required
              fullWidth
              id="email"
              label={currentLabels.email}
              name="email"
              value={formValues.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email}
              helperText={errors.email}
              className="input-email-field"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'transparent !important',
                  borderRadius: '8px',
                  color: 'var(--primary-text-color)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--General-Stroke) !important',
                  '&:hover': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '&:focus-within': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'blue', // Màu chữ placeholder
                  },
                },
              }}

            />
            <TextField
              required
              fullWidth
              name="password"
              label={currentLabels.password}
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={formValues.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.password}
              helperText={errors.password}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'transparent !important',
                  borderRadius: '8px',
                  color: 'var(--primary-text-color)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--General-Stroke) !important',
                  '&:hover': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '&:focus-within': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="input-password-field"
            />
            <TextField
              required
              fullWidth
              name="fullName"
              label={currentLabels.fullName}
              id="fullName"
              value={formValues.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.fullName}
              helperText={errors.fullName}
              className="input-fullname-field"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'transparent !important',
                  borderRadius: '8px',
                  color: 'var(--primary-text-color)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--General-Stroke) !important',
                  '&:hover': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '&:focus-within': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                },
              }}
            />
            <TextField
              required
              fullWidth
              name="phoneNumber"
              label={currentLabels.phoneNumber}
              id="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              className="input-phone-field"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'transparent !important',
                  borderRadius: '8px',
                  color: 'var(--primary-text-color)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--General-Stroke) !important',
                  '&:hover': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                  '&:focus-within': {
                    borderColor: 'var(--General-Stroke) !important',
                  },
                },
              }}
            />
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(token) => setRecaptchaToken(token)}
              className="recaptcha-container"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="register-button"
              disabled={isSubmitting}
              sx={{
                borderRadius: '8px',
                background: 'var(--Color-Style-Primary-Gradient-Primary)',
                display: 'flex',
                height: '55px',
                padding: '8px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                alignSelf: 'stretch',
                color: 'var(--Button-button-label-primary)',
                textAlign: 'center',
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
                '&:hover': {
                  background:
                    'var(--Color-Style-Primary-Gradient-Primary-hover)',
                },
              }
              }
            >
              {isSubmitting ? currentLabels.register : currentLabels.signUp}
            </Button>
            <Box className="register-link">
              <Typography variant="body2">
                {currentLabels.doneAccount}{' '}
                <Link sx={{
                  textDecoration: 'underline',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: 'var(--primary-text-color)',
                  transitionDuration: 'var(--transition-duration)',
                  transitionTimingFunction: 'var(--transition-timing-function)',
                  '&:hover': {
                    color: 'var(--primary-text-color-hover)',
                  },
                }} href="/login" >{currentLabels.signIn}</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </FormContainer>
  );
}

export default Register;
