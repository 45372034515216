import React from 'react';

interface IStocktradersBot {
  width?: string | number;
  height?: string | number;
}

const StocktradersBot: React.FC<IStocktradersBot> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1426 3L20.1426 6.80766"
        stroke="url(#paint0_linear_619_6595)"
        stroke-width="1.5"
      />
      <mask id="path-2-inside-1_619_6595" fill="white">
        <ellipse cx="3.85725" cy="2.26922" rx="1.28571" ry="1.26922" />
      </mask>
      <path
        d="M3.64296 2.26922C3.64296 2.12356 3.75722 2.03844 3.85725 2.03844V5.03844C5.37743 5.03844 6.64296 3.81682 6.64296 2.26922H3.64296ZM3.85725 2.03844C3.95727 2.03844 4.07153 2.12356 4.07153 2.26922H1.07153C1.07153 3.81682 2.33706 5.03844 3.85725 5.03844V2.03844ZM4.07153 2.26922C4.07153 2.41488 3.95727 2.5 3.85725 2.5V-0.5C2.33706 -0.5 1.07153 0.721619 1.07153 2.26922H4.07153ZM3.85725 2.5C3.75722 2.5 3.64296 2.41488 3.64296 2.26922H6.64296C6.64296 0.721619 5.37743 -0.5 3.85725 -0.5V2.5Z"
        fill="url(#paint1_linear_619_6595)"
        mask="url(#path-2-inside-1_619_6595)"
      />
      <path
        d="M3.85742 3L3.85742 6.80766"
        stroke="url(#paint2_linear_619_6595)"
        stroke-width="1.5"
      />
      <mask id="path-5-inside-2_619_6595" fill="white">
        <ellipse cx="20.1431" cy="2.26922" rx="1.28571" ry="1.26922" />
      </mask>
      <path
        d="M19.9289 2.26922C19.9289 2.12356 20.0431 2.03844 20.1431 2.03844V5.03844C21.6633 5.03844 22.9289 3.81682 22.9289 2.26922H19.9289ZM20.1431 2.03844C20.2432 2.03844 20.3574 2.12356 20.3574 2.26922H17.3574C17.3574 3.81682 18.6229 5.03844 20.1431 5.03844V2.03844ZM20.3574 2.26922C20.3574 2.41488 20.2432 2.5 20.1431 2.5V-0.5C18.6229 -0.5 17.3574 0.721619 17.3574 2.26922H20.3574ZM20.1431 2.5C20.0431 2.5 19.9289 2.41488 19.9289 2.26922H22.9289C22.9289 0.721619 21.6633 -0.5 20.1431 -0.5V2.5Z"
        fill="url(#paint3_linear_619_6595)"
        mask="url(#path-5-inside-2_619_6595)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 23.0009C18.6274 23.0009 24 20.2487 24 14.2976C24 8.34655 18.6274 2.69336 12 2.69336C5.37258 2.69336 0 8.34655 0 14.2976C0 20.2487 5.37258 23.0009 12 23.0009ZM7.22509 11.8086H16.7746C18.5164 11.8086 19.9284 13.2206 19.9284 14.9624C19.9284 16.7042 18.5164 18.1162 16.7746 18.1162H7.22509C5.4833 18.1162 4.07129 16.7042 4.07129 14.9624C4.07129 13.2206 5.48329 11.8086 7.22509 11.8086ZM2.57129 14.9624C2.57129 12.3922 4.65487 10.3086 7.22509 10.3086H16.7746C19.3449 10.3086 21.4284 12.3922 21.4284 14.9624C21.4284 17.5326 19.3449 19.6162 16.7746 19.6162H7.22509C4.65487 19.6162 2.57129 17.5326 2.57129 14.9624ZM7.28544 15.5774C7.6588 15.5774 7.92829 15.2837 7.92829 14.962C7.92829 14.6404 7.6588 14.3467 7.28544 14.3467C6.91207 14.3467 6.64258 14.6404 6.64258 14.962C6.64258 15.2837 6.91207 15.5774 7.28544 15.5774ZM7.28544 17.0774C8.4689 17.0774 9.42829 16.1303 9.42829 14.962C9.42829 13.7938 8.4689 12.8467 7.28544 12.8467C6.10197 12.8467 5.14258 13.7938 5.14258 14.962C5.14258 16.1303 6.10197 17.0774 7.28544 17.0774ZM17.357 14.962C17.357 15.2837 17.0875 15.5774 16.7141 15.5774C16.3408 15.5774 16.0713 15.2837 16.0713 14.962C16.0713 14.6404 16.3408 14.3467 16.7141 14.3467C17.0875 14.3467 17.357 14.6404 17.357 14.962ZM18.857 14.962C18.857 16.1303 17.8976 17.0774 16.7141 17.0774C15.5307 17.0774 14.5713 16.1303 14.5713 14.962C14.5713 13.7938 15.5307 12.8467 16.7141 12.8467C17.8976 12.8467 18.857 13.7938 18.857 14.962ZM10 7.86523C10 7.45102 10.3358 7.11523 10.75 7.11523H13.25C13.6642 7.11523 14 7.45102 14 7.86523C14 8.27945 13.6642 8.61523 13.25 8.61523H10.75C10.3358 8.61523 10 8.27945 10 7.86523ZM11.75 5.42383C11.3358 5.42383 11 5.75961 11 6.17383C11 6.58804 11.3358 6.92383 11.75 6.92383H12.25C12.6642 6.92383 13 6.58804 13 6.17383C13 5.75961 12.6642 5.42383 12.25 5.42383H11.75Z"
        fill="url(#paint4_linear_619_6595)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_619_6595"
          x1="19.6426"
          y1="3"
          x2="17.979"
          y2="3.97807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_619_6595"
          x1="2.57153"
          y1="2.26922"
          x2="4.70592"
          y2="3.23504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_619_6595"
          x1="3.35742"
          y1="3"
          x2="1.69383"
          y2="3.97807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_619_6595"
          x1="18.8574"
          y1="2.26922"
          x2="20.9918"
          y2="3.23504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_619_6595"
          x1="-3.66548e-07"
          y1="12.8471"
          x2="18.769"
          y2="22.7557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StocktradersBot;
