import React from 'react';

import FormContainer from 'components/Form/FormContainer';
import { Container, Typography, Box } from '@mui/material';

import MailSent from 'assets/images/mail-sent.png';

const EmailSent = () => {
  return (
    <FormContainer>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={MailSent}
          alt="Mail Sent"
          sx={{ width: '100%', maxWidth: 120, margin: '0 auto' }}
        />
        <Typography
          sx={{
            textAlign: 'center',
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Roboto',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '116.7%',
            letterSpacing: '2px',
            background: 'var(--Color-Style-Primary-Gradient-Primary)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Check your mail
        </Typography>
        <Typography
          sx={{
            color: 'var(--Text-text-placeholder)',
            textAlign: 'center',
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '0.15px',
          }}
        >
          We have sent a password recovery instructions to your email.
        </Typography>
      </Container>
    </FormContainer>
  );
};

export default EmailSent;
