import React from 'react';

import { useNavigate } from 'react-router-dom';

import { IconButton, SxProps, Theme } from '@mui/material';
import ArrowLeft from 'assets/svgs/arrowLeft';

interface IReturnButton {
  style?: SxProps<Theme>;
}

const ReturnButton: React.FC<IReturnButton> = ({ style }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <IconButton
      onClick={handleBack}
      sx={{
        width: '48px',
        height: '48px',
        background: 'none',
        padding: '0',
        ...style
      }}
    >
      <ArrowLeft />
    </IconButton>
  );
};

export default ReturnButton;
