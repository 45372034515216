import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SuggestionButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const SuggestionButton: React.FC<SuggestionButtonProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      {...props}
      sx={{
        fontSize: {
          xs: '0.6rem', // Smaller font size on extra-small screens
          sm: '0.75rem', // Default font size on small screens and up
        },
        color: 'var(--text-color)', // Gray text color that adapts to light/dark mode
        borderColor: theme.palette.grey[500], // Gray border color
        textTransform: 'none', // Optional: to keep the text case as provided
        whiteSpace: 'nowrap', // Prevents text from wrapping
        minWidth: {
          xs: 'auto', // Auto width on extra-small screens
          sm: '100px', // Fixed width on small screens and up
        },
        padding: {
          xs: '4px 8px', // Smaller padding on extra-small screens
          sm: '6px 12px', // Default padding on small screens and up
        },
        borderRadius: '20px',
        mr: {
          xs: '2px', // Smaller margin on extra-small screens
          sm: '5px', // Default margin on small screens and up
        },
        // backgroundColor: 'var(--General-surface-primary-2)', // Allows button width to adjust to content
      }}
    >
      {children}
    </Button>
  );
};

export default SuggestionButton;
