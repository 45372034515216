import React, { useState } from 'react';

import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import { API_URL } from '../../api/client';

import {
  Container,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Checkbox,
  Button,
} from '@mui/material';

interface Permissions {
  noti_branch?: boolean;
  stock_signal_real?: boolean;
  stock_wave_real?: boolean;
}

function CreateUser() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [level, setLevel] = useState<number>(0);
  const [permissions, setPermissions] = useState<Permissions>({});
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}/create-user`,
        {
          username,
          password,
          level,
          permissions,
        },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        },
      );
      // alert('User created successfully');
      setUsername('');
      setPassword('');
      setLevel(0);
      setPermissions({});
    } catch (error) {
      console.error('Failed to create user:', error);
      alert('Failed to create user');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Create New User
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Level</InputLabel>
          <Select
            value={level}
            onChange={(e) => setLevel(e.target.value as number)}
          >
            <MenuItem value={0}>Free</MenuItem>
            <MenuItem value={1}>Paid</MenuItem>
            <MenuItem value={2}>CS</MenuItem>
            <MenuItem value={3}>Admin</MenuItem>
            <MenuItem value={4}>Super Admin</MenuItem>
          </Select>
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.noti_branch}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    noti_branch: e.target.checked,
                  })
                }
              />
            }
            label="noti_branch"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.stock_signal_real}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    stock_signal_real: e.target.checked,
                  })
                }
              />
            }
            label="stock_signal_real"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.stock_wave_real}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    stock_wave_real: e.target.checked,
                  })
                }
              />
            }
            label="stock_wave_real"
          />
        </FormGroup>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create User
        </Button>
      </form>
    </Container>
  );
}

export default CreateUser;
