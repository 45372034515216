import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import { useLanguage } from 'contexts/LanguageContext';
import { labels } from './languageLabels';
import ResetEmailImg from '../../assets/images/ResetEmail.png';
import './SuccessSendEmail.scss'; // Ensure this path is correct

function SendEmailSuccess() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const currentLabels = labels[language] || labels['en'];

  useEffect(() => {
    const timer = setTimeout(() => navigate('/reset-password'), 2000); // 4 seconds
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      className="form-container-custom"
      sx={{borderRadius: "20px solid --General-Stroke"

      }}
    >
      <Dialog open={true} onClose={() => navigate('/reset-password')}>
        <DialogContent>
          <Box
            textAlign="center"
            mt={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            
          >
            <img
              src={ResetEmailImg}
              alt="Reset Password"
              style={{ width: '80px', height: '80px', marginBottom: '20px', borderRadius: '23%' }}
            />
            <Typography
              className="gradient-text" // Ensure this class is defined in your CSS
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '32px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '116.7%', // or use a fixed value if preferred
                letterSpacing: '1px',
              }}
            >
              {currentLabels.titleResetPasswordSuccess}
            </Typography>
            <Typography
              sx={{
                color: 'var(--Text-text-placeholder, #919496)', // Use correct CSS variable
                textAlign: 'center',
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%', // Use '24px' if preferred
                letterSpacing: '0.15px',
              }}
            >
              {currentLabels.sendResetPasswordSuccess}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default SendEmailSuccess;
