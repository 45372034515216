import React from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: 'var(--button-choose-price)',
  borderRadius: 28,
  boxShadow: 'none',
  '& .MuiButton-root': {
    backgroundColor: 'var(--button-choose-price)',
    borderRadius: 28,
    padding: '8px 20px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-selected': {
      background: 'var(--Color-Style-Primary-Gradient-Primary)',
      color: 'var(--Button-button-background-secondary)',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontStyle: 'normal',
      textTransform: 'none',
      margin: '4px',
    },
    '&.MuiButtonGroup-grouped': {
      border: 'none',
      borderRadius: 28,
      '&:not(:last-child)': {
        borderRight: 'none',
      },
    },
  },
}));

interface Props {
  selected: 'year' | 'month';
  onChange: (selected: 'year' | 'month') => void;
}

const ChosePriceButton: React.FC<Props> = ({ selected, onChange }) => {
  return (
    <StyledButtonGroup variant="contained" aria-label="outlined primary button group">
      <Button
        disableRipple
        className={selected === 'year' ? 'Mui-selected' : ''}
        onClick={(event) => {
          event.stopPropagation();
          onChange('year');
        }}
      >
        <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          Gói Năm
          <span role="img" aria-label="fire" style={{ marginLeft: '4px' }}>
            🔥
          </span>
        </Typography>
      </Button>
      <Button
        disableRipple
        className={selected === 'month' ? 'Mui-selected' : ''}
        onClick={(event) => {
          event.stopPropagation();
          onChange('month');
        }}
      >
        <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          Gói Tháng
        </Typography>
      </Button>
    </StyledButtonGroup>
  );
};

export default ChosePriceButton;