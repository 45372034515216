import React from 'react';

interface ArrowLeftProps {
  width?: string | number;
  height?: string | number;
}

const ArrowLeft: React.FC<ArrowLeftProps> = ({ width = 48, height = 49 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 16.5L12 24.5M12 24.5L20 32.5M12 24.5L36 24.5"
        stroke="#919496"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
