import React, { useEffect } from "react";

import { Box, Grid, Typography, IconButton } from "@mui/material";
import CardPriceItem from "./CartPriceItem";
import ChosePriceButton from "./ButtonChoosePrice";
import CloseIcon from "@mui/icons-material/Close";

import useMobile from "hooks/useMobile";
import { Phone } from "@mui/icons-material";

const pricingPlansYear = [
  {
    title: "Cơ Bản",
    price: "20.990.000 /năm",
    originalPrice: "33.880.000 / năm",
    salePrice: "Chỉ còn 1.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "20 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường cơ bản",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
  {
    title: "Tiêu Chuẩn",
    price: "30.990.000 /năm",
    originalPrice: "43.880.000 / năm",
    salePrice: "Chỉ còn 2.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "20 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Chi tiết khuyến nghị giao dịch",
      "Chi tiết sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
  {
    title: "Chuyên Nghiệp",
    price: "40.990.000 /năm",
    originalPrice: "53.880.000 / năm",
    salePrice: "Chỉ còn 3.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "25 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường chuyên sâu",
      "Lịch sử khuyến nghị giao dịch",
      "Chi tiết sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp ngay",
    highlightFeatures: [],
    public: "Phổ biến",
  },
  {
    title: "Cao Cấp",
    price: "50.990.000 /năm",
    originalPrice: "63.880.000 / năm",
    salePrice: "Chỉ còn 4.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "Không giới hạn",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường chuyên sâu",
      "Lịch sử khuyến nghị",
      "Chi tiết sóng ngành",
      "Tổng quan sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
];

const pricingPlansMonth = [
  {
    title: "Cơ Bản",
    price: "2.990.000 /tháng",
    originalPrice: "",
    salePrice: "Chỉ còn 1.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "20 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường cơ bản",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
  {
    title: "Tiêu Chuẩn",
    price: "3.990.000/ tháng",
    originalPrice: "",
    salePrice: "Chỉ còn 2.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "20 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Chi tiết khuyến nghị giao dịch",
      "Chi tiết sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
  {
    title: "Chuyên Nghiệp",
    price: "4.990.000/ tháng",
    originalPrice: "",
    salePrice: "Chỉ còn 3.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "25 lượt / ngày",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường chuyên sâu",
      "Lịch sử khuyến nghị giao dịch",
      "Chi tiết sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp ngay",
    highlightFeatures: [],
    public: "Phổ biến",
  },
  {
    title: "Cao Cấp",
    price: "5.990.000/ tháng",
    originalPrice: "",
    salePrice: "Chỉ còn 4.990.000/ tháng",
    queryText: "Truy vấn giới hạn",
    limitedQuery: "không giới hạn",
    features: [
      "Khuyến nghị giao dịch",
      "Báo sóng thị trường",
      "Dò sóng thị trường chuyên sâu",
      "Lịch sử khuyến nghị",
      "Chi tiết sóng ngành",
      "Tổng quan sóng ngành",
      "Hướng dẫn sử dụng hệ thống",
      "Trả lời các câu hỏi về triết lý, đầu tư trong hệ thống",
    ],
    buttonText: "Nâng cấp",
    highlightFeatures: [],
    public: "",
  },
];

interface IPricingOptionsProps {
  pricingOptionRef: React.RefObject<HTMLDivElement>;
  setIsPriceOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PricingOptions: React.FC<IPricingOptionsProps> = ({
  pricingOptionRef,
  setIsPriceOptionsOpen,
}) => {
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsPriceOptionsOpen]);

  const isMobile = useMobile();

  const handleClickOutside = (event: MouseEvent) => {
    if (
      pricingOptionRef.current &&
      !pricingOptionRef.current.contains(event.target as Node)
    ) {
      setIsPriceOptionsOpen(false);
    }
  };
  const [selectedPlan, setSelectedPlan] = React.useState<"year" | "month">(
    "month"
  );
  const handleTogglePlans = (plan: "year" | "month") => {
    setSelectedPlan(plan);
  };
  const plansToDisplay =
    selectedPlan === "month" ? pricingPlansMonth : pricingPlansYear;

  return (
    <Box
      ref={pricingOptionRef}
      sx={{
        backgroundColor: "var(--background-color-chat-container)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: {
          xs: "95%",
          sm: "90%",
          md: "84%",
        },
        margin: "0 auto",
        pt: {
          xs: "20px",
          sm: "25px",
          md: "30px",
        },
        zIndex: 1000,
      }}
    >
      {isMobile && (
        <IconButton
          onClick={() => {
            setIsPriceOptionsOpen(false);
          }}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box
        sx={{
          backgroundColor: "var(--background-color-chat-container)",
          color: "white",
          py: 5,
          px: 3,
        }}
      >
        <Typography
          variant="h5"
          align="center"
          mb={2}
          style={{
            background:
              "linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "block",
            textAlign: "center",
            width: "100%",
            fontWeight: "bold",
            fontSize: "28px", // Tăng kích thước font
          }}
        >
          Bảng giá Chatbot của Al StockTraders
        </Typography>
        <Typography
          variant="body1"
          align="center"
          mb={3} // Tăng margin bottom
          sx={{
            fontSize: "14px", // Tăng kích thước font từ 12px lên 14px
            color: "var(--primary-text-color)",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Khám phá gói giá cạnh tranh phù hợp, tham gia ngay hôm nay <br />
          là bắt đầu bước đầu tiên hướng tới giao dịch thông minh
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <ChosePriceButton
            selected={selectedPlan}
            onChange={handleTogglePlans}
          />
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {" "}
          {plansToDisplay.map((plan) => {
            const isPhoBien = (plan.public !== "");

            return (
              <Grid item xs={12} md={3} key={plan.title}>
                <CardPriceItem
                  isPhoBien={isPhoBien}
                  title={plan.title}
                  price={plan.price}
                  originalPrice={plan.originalPrice}
                  salePrice={plan.salePrice}
                  queryText={plan.queryText}
                  limitedQuery={plan.limitedQuery}
                  features={plan.features}
                  buttonText={plan.buttonText}
                  highlightFeatures={plan.highlightFeatures}
                  public={plan.public}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default PricingOptions;