import React from 'react';

import { Routes, Route } from 'react-router-dom';

import ProtectedRoute from 'functions/Authentication/ProtectedRoute';
import Login from 'functions/Authentication/Login';
import Register from 'functions/Authentication/Register';
import SetupPassword from 'functions/Authentication/SetupPassword';
import UserManagement from 'functions/Users/UserManagement';
import CreateUser from 'functions/Users/CreateUser';
import CreateSuperAdmin from 'functions/Admin/CreateSuperAdmin';
import MainApp from 'components/MainApp';
import BackgroundWrapper from 'components/BackgroundWrapper';
import AuthLayout from 'components/AuthLayout';
import ResetPassword from 'functions/Authentication/ResetPassword';
import MyAccount from 'functions/Users/MyAccount';
import MessageManagement from 'functions/Messages/MessageManagement';
import EmailSent from 'functions/Authentication/EmailSent';
import SuccessRegister from 'functions/Authentication/RegisterSuccess';
import Waitlist from 'functions/Authentication/Waitlist';

const ProtectedRoutes: React.FC = () => {
  return (
    <BackgroundWrapper>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          path="/register"
          element={
            <AuthLayout>
              <Register />
            </AuthLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          }
        />
        <Route
          path="/setup-password"
          element={
            <AuthLayout>
              <SetupPassword />
            </AuthLayout>
          }
        />
        <Route
          path="/email-verification-complete"
          element={
            <AuthLayout>
              <EmailSent />
            </AuthLayout>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-user"
          element={
            <ProtectedRoute>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-super-admin"
          element={
            <ProtectedRoute>
              <CreateSuperAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <MyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/message-management"
          element={
            <ProtectedRoute>
              <MessageManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainApp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/success-register"
          element={
            <AuthLayout>
              <SuccessRegister />
            </AuthLayout>
          }
        />
        <Route
          path="/success-send-email"
          element={
            <AuthLayout>
              <SuccessRegister />
            </AuthLayout>
          }
        />
        {/* Thêm route mới cho trang Waitlist */}
        <Route
          path="/waitlist"
          element={
            <AuthLayout>
              <Waitlist />
            </AuthLayout>
          }
        />
      </Routes>
    </BackgroundWrapper>
  );
};

export default ProtectedRoutes;
