import React, { createContext, useState, useContext } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../api/client';
import axios from 'axios';

interface UserContextType {
    user: any;
    setUser: React.Dispatch<React.SetStateAction<any>>;
}

const UserContext = createContext<UserContextType | null>(null);

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();

    const { isLoading } = useQuery(
        'user',
        async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get(`${API_URL}/users/me`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUser(response.data);
                    return response.data;
                } catch (error: any) {
                    console.error('Auth check failed:', error.response || error);
                    localStorage.removeItem('token');
                    navigate('/login');
                    throw error;
                }
            } else {
                navigate('/login');
                return null;
            }
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        }
    );

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {isLoading ? <div>Loading...</div> : children}
        </UserContext.Provider>
    );
};
