import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext'; // Import LanguageProvider
import AuthenticatedApp from 'functions/Authentication/AuthenticatedApp';
import { GlobalStyle } from './styles/GlobalStyles';
import { ChatProvider } from 'contexts/ChatContext';
function AppRouter() {
  return (
    <Router>
      <LanguageProvider> {/* Add LanguageProvider here */}
        <AuthProvider>
          <ChatProvider>
          <AuthenticatedApp />
          </ChatProvider>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
}

function WrappedApp() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <AppRouter />
    </QueryClientProvider>
  );
}

export default WrappedApp;
