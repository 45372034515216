import React, { useState } from 'react';
import { Box } from '@mui/material';
import like from '../assets/svgs/like.svg';
import dislike from '../assets/svgs/dislike.svg';

export interface IMessage {
  id: string;
  rating: number;
}

interface IMessageActionsProps {
  message: IMessage;
  rateMessage: (id: string, rating: number) => void;
}

const MessageActions: React.FC<IMessageActionsProps> = ({ message, rateMessage }) => {
  const [activeRating, setActiveRating] = useState<number | null>(null);

  const handleRate = (rating: number) => {
    rateMessage(message.id, rating);
    setActiveRating(rating);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginRight: '5px',
          fontSize: '0.8rem',
          transition: 'transform 0.3s',
          cursor: 'pointer',
          transform: activeRating === 1 ? 'scale(1.5)' : 'scale(1)',
          color: message.rating === 1 ? 'secondary' : 'inherit',
          '&:hover': {
            transform: 'scale(1.2)',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleRate(1);
        }}
      >
        <img src={like} alt="like" style={{ width: '16px', height: '16px' }} />
      </Box>
      <Box
        sx={{
          marginRight: '5px',
          fontSize: '0.8rem',
          transition: 'transform 0.3s',
          cursor: 'pointer',
          transform: activeRating === 0 ? 'scale(1.5)' : 'scale(1)',
          color: message.rating === 0 ? 'secondary' : 'inherit',
          '&:hover': {
            transform: 'scale(1.2)',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleRate(0);
        }}
      >
        <img src={dislike} alt="dislike" style={{ width: '16px', height: '16px' }} />
      </Box>
    </Box>
  );
};

export default MessageActions;
