import axios from 'axios';
import qs from 'qs'; 
const API_URL = 'https://aistocktraders.vn/api';



// POST /api/login
export const login = async (credentials) => {
  try {
    // Gửi yêu cầu POST
    const response = await axios.post(`${API_URL}/login`, qs.stringify({

      recaptcha_token: credentials.recaptchaToken || "string",
      grant_type: "password", // Bạn có thể cần cập nhật giá trị phù hợp
      username: credentials.username,
      password: credentials.password,
      scope: "string", // Bạn có thể cần cập nhật giá trị phù hợp
      client_id: "string", // Bạn cần thay thế bằng giá trị hợp lệ
      client_secret: "string" // Bạn cần thay thế bằng giá trị hợp lệ
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    // Xử lý phản hồi thành công
    // console.log("Username from response:", response.data.username); // Kiểm tra cấu trúc phản hồi để truy cập đúng thuộc tính
    // console.log("Full response data:", response.data);
    return response.data;

  } catch (error) {
    // Xử lý lỗi
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else {
      console.error('Error message:', error.message);
    }
    throw new Error('Login failed');
  }
};



// Define the register function
export const registerUser = async (userData) => {
  try {
      // Sử dụng URL trực tiếp mà không thay đổi
      const url = `${API_URL}/register`;

      const response = await axios.post(url, userData);
      return response.data;
  } catch (error) {
      // Handle errors (customize this as needed)
      throw new Error(error.response?.data?.detail || 'Registration failed');
  }
};


export const sendResetPasswordRequest = async (email, recaptchaToken) => {
  try {
    const response = await axios.post(`${API_URL}/reset-password-request`, {
      email,
      recaptcha_token: recaptchaToken, // Use the passed recaptchaToken
    });
    console.log("response" + response)
    return response;
  } catch (error) {
    // Handle errors
    const errorMessage = error.response?.data?.detail || error.message || 'An unknown error occurred';
    throw new Error(errorMessage);
  }
};

export const resetPassword = async (token, newPassword, recaptchaToken) => {
  try {
    const response = await axios.post(`${API_URL}/reset-password`, {
      token,
      new_password: newPassword,
      recaptcha_token: recaptchaToken, // Use the passed recaptchaToken
    });
    console.log("response" + response)
    return response;
  } catch (error) {
    // Handle errors
    const errorMessage = error.response?.data?.detail || error.message || 'An unknown error occurred';
    throw new Error(errorMessage);
  }
}

export const loginWithGoogle = async (credential) => {
  try {
    const response = await axios.post(`${API_URL}/google-login`, { token: credential });
    console.log("Google login API response:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error in Google login:', error.response?.data || error.message);
    throw new Error(error.response?.data?.detail || 'Google login failed');
  }
};