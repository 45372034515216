import React from 'react';

interface IBackgroundWrapper {
  children: React.ReactNode;
}

const BackgroundWrapper: React.FC<IBackgroundWrapper> = ({ children }) => {
  return <div className="app-background">{children}</div>;
};

export default BackgroundWrapper;
