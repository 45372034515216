// /root/Viet/Frontend/src/functions/Authentication/Login.tsx

import "./Login.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import FormContainer from "components/Form/FormContainer";
import { Container } from "styles/GlobalStyles";
import {
  TextField,
  Button,
  Checkbox,
  Box,
  Typography,
  FormControlLabel,
  Link,
  InputAdornment,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Separator from "components/Separator";
import { RECAPTCHA_SITE_KEY } from "../../config";
import { useLanguage } from "contexts/LanguageContext";
import { labels } from "./languageLabels";
import { useCreateNewChat } from "hooks/userCreateNewChat";
import useMobile from "hooks/useMobile";

type FormValues = {
  [key: string]: string;
};

const API_URL =
  process.env.REACT_APP_API_URL || "https://aistocktraders.vn/api";

function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [errors, setErrors] = useState<FormValues>({});
  const { language } = useLanguage();
  const auth = useAuth();
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const isMobile = useMobile();
  const { createNewChat } = useCreateNewChat();
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Vui lòng hoàn thành reCAPTCHA");
      return;
    }

    try {
      // Tạo đối tượng credentials với các giá trị cần thiết
      const credentials = {
        username: username.toLowerCase(), // Đảm bảo chuyển đổi username thành chữ thường
        password,
        rememberMe,
        recaptchaToken,
        grantType: "password",
        scope: "", // Thay thế bằng giá trị phù hợp nếu cần
        clientId: "", // Thay thế bằng client ID thực tế nếu cần
        clientSecret: "", // Thay thế bằng client secret thực tế nếu cần
      };

      // Gọi API đăng nhập với credentials
      const response = await auth.login(credentials);

      // Kiểm tra kết quả từ API
      if (response) {
        console.log("response: " + JSON.stringify(response, null, 2));
        const access_token = response.access_token;
        console.log("token: " + access_token);

        if (access_token) {
          // Lưu access_token và user
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("user", JSON.stringify(response));
          auth.setUser(response);

          // Kiểm tra trạng thái của người dùng
          if (response.status === "waitlist") {
            navigate("/waitlist");
            return;
          }

          setIsCreatingSession(true);

          const newSessionId = await createNewChat(access_token);
          if (newSessionId) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            localStorage.setItem("showWelcomeDialog", "true");
            setIsCreatingSession(false); // Kết thúc hiệu ứng loading
            navigate("/");
          } else {
            setIsCreatingSession(false); // Kết thúc hiệu ứng loading nếu có lỗi
            console.error("Không thể tạo phiên chat mới");
            alert(
              "Đăng nhập thành công nhưng không thể tạo phiên chat mới. Vui lòng thử lại."
            );
          }
        } else {
          alert("Không tìm thấy token xác thực.");
        }
      } else {
        alert("Đăng nhập thất bại. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Đăng nhập thất bại:", error);
      alert("Đăng nhập thất bại. Vui lòng thử lại.");
      recaptchaRef.current?.reset();
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("access_token");
      const user = localStorage.getItem("user");
      
      if (token && user) {
        try {
          // Thực hiện kiểm tra token với backend
          const response = await fetch(`${API_URL}/verify-token`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            // Token hợp lệ, đặt lại thông tin người dùng
            auth.setUser(JSON.parse(user));
            navigate("/");
          } else {
            // Token không hợp lệ, xóa thông tin đăng nhập
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
          }
        } catch (error) {
          console.error("Lỗi khi kiểm tra trạng thái xác thực:", error);
        }
      }
    };

    checkAuthStatus();
  }, [navigate, auth]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleResetPassword = () => {
    navigate("/reset-password");
  };

  const handleRegister = () => {
    navigate("/register");
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleGoogleSignIn = useCallback(
    async (response: any) => {
      try {
        console.log("Google Sign-In Response:", response);
        if (response.credential) {
          const result = await auth.loginWithGoogle(response.credential);
          console.log("Google login result:", result);
          if (result && result.access_token) {
            // Lưu access_token và thông tin người dùng
            localStorage.setItem("access_token", result.access_token);
            localStorage.setItem("user", JSON.stringify(result));
            auth.setUser(result);

            // Tạo phiên chat mới
            const access_token = result.access_token;
            const newSessionId = await createNewChat(access_token);

            if (newSessionId) {
              await new Promise((resolve) => setTimeout(resolve, 2000));
              localStorage.setItem("showWelcomeDialog", "true");
              navigate("/");
            } else {
              console.error("Không thể tạo phiên chat mới");
              alert(
                "Đăng nhập thành công nhưng không thể tạo phiên chat mới. Vui lòng thử lại."
              );
            }
          } else {
            console.error("Định dạng phản hồi không đúng:", result);
            alert("Đăng nhập Google thất bại: Định dạng phản hồi không đúng");
          }
        } else {
          console.error("Không có thông tin xác thực trong phản hồi Google");
          alert("Đăng nhập Google thất bại: Không có thông tin xác thực");
        }
      } catch (error: any) {
        console.error("Lỗi đăng nhập Google:", error);
        alert("Đăng nhập Google thất bại: " + error.message);
      }
    },
    [navigate, createNewChat, auth]
  );

  useEffect(() => {
    if (!document.getElementById("googleSignInScript")) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.id = "googleSignInScript";
      document.body.appendChild(script);

      script.onload = () => {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id:
              "1082515086718-t2qudsio26hc7hg0isijp3fkpduef6nj.apps.googleusercontent.com",
            callback: handleGoogleSignIn,
          });
          window.google.accounts.id.renderButton(
            document.getElementById("googleSignInDiv"),
            { theme: "outline", size: "large" }
          );
        }
      };

      script.onerror = () => {
        console.error("Không thể tải script đăng nhập Google.");
      };
    }

    return () => {
      const existingScript = document.getElementById("googleSignInScript");
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [handleGoogleSignIn]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} là bắt buộc`,
      }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const { name, value } = e.target;

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    setValue(value);
  };

  return (
    <FormContainer>
      <Container className="login-container">
        <Box className="login-form">
          <Typography
            sx={{
              color: "var(--Text-text-primary)",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Roboto, Arial, sans-serif",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {labels[language as "en" | "vi"].signIn}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              "@media(max-width: 768px)": {
                gap: "30px",
              },
            }}
          >
            {/* Trường tên người dùng */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 100,
                  lineHeight: "16px",
                  letterSpacing: "0.15px",
                }}
              >
                {labels[language as "en" | "vi"].username}
              </Typography>
              <TextField
                placeholder={labels[language as "en" | "vi"].enterUsername}
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                helperText={errors.username}
                onBlur={handleBlur}
                onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleChange(e, setUsername)
                }
                error={!!errors.username}
                className="input-username-field"
              />
            </Box>
            {/* Trường mật khẩu */}
            <Box className="password-box">
              <Typography
                sx={{
                  color: "var(--primary-text-color)",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "100",
                  lineHeight: "16px",
                  letterSpacing: "0.15px",
                }}
              >
                {labels[language as "en" | "vi"].password}
              </Typography>
              <TextField
                placeholder={labels[language as "en" | "vi"].enterPassword}
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                helperText={errors.password}
                onBlur={handleBlur}
                onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleChange(e, setPassword)
                }
                error={!!errors.password}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "transparent !important",
                    borderRadius: "8px",
                    color: "var(--primary-text-color)",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--General-Stroke) !important",
                    "&:hover": {
                      borderColor: "var(--General-Stroke) !important",
                    },
                    "&:focus-within": {
                      borderColor: "var(--General-Stroke) !important",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{
                          color: "var(--General-Stroke)",
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {/* Ghi nhớ đăng nhập và quên mật khẩu */}
            <div className="remember-me-container">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "var(--primary-text-color)",
                      "&.Mui-checked": {
                        color: "var(--primary-text-color-hover)",
                      },
                    }}
                    value="remember"
                    color="primary"
                  />
                }
                label={labels[language as "en" | "vi"].rememberMe}
                checked={rememberMe}
                onChange={(e) =>
                  setRememberMe((e.target as HTMLInputElement).checked)
                }
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "var(--primary-text-color)",
                  },
                }}
              />
              <div style={{ marginLeft: "auto" }}>
                <Link
                  sx={{
                    textDecoration: "underline",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "var(--primary-text-color)",
                    "&:hover": {
                      color: "var(--primary-text-color-hover)",
                    },
                  }}
                  href="#"
                  onClick={handleResetPassword}
                >
                  {labels[language as "en" | "vi"].forgotPassword}
                </Link>
              </div>
            </div>
            {/* reCAPTCHA */}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
            {/* Nút đăng nhập */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                borderRadius: "8px",
                background: "var(--Color-Style-Primary-Gradient-Primary)",
                display: "flex",
                height: "55px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                alignSelf: "stretch",
                color: "var(--Button-button-label-primary)",
                textAlign: "center",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "175%",
                letterSpacing: "0.15px",
                "&:hover": {
                  background:
                    "var(--Color-Style-Primary-Gradient-Primary-hover)",
                },
              }}
            >
              {labels[language as "en" | "vi"].signIn}
            </Button>
            {/* Liên kết đăng ký */}
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {labels[language as "en" | "vi"].noAccount}{" "}
              <Link
                href="#"
                onClick={handleRegister}
                sx={{
                  textDecoration: "underline",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  color: "var(--primary-text-color)",
                  transitionDuration: "var(--transition-duration)",
                  transitionTimingFunction: "var(--transition-timing-function)",
                  "&:hover": {
                    color: "var(--primary-text-color-hover)",
                  },
                }}
              >
                {labels[language as "en" | "vi"].register}
              </Link>
            </Typography>
            {/* Phân cách và nút đăng nhập Google */}
            <Separator title="hoặc" />
            <div id="googleSignInDiv"></div>
          </Box>
        </Box>
      </Container>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={isCreatingSession}
      >
        <CircularProgress color="inherit" />
        <Typography
          sx={{
            mt: 2,
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          Đăng nhập thành công
        </Typography>
      </Backdrop>
    </FormContainer>
  );
}

export default Login;
