import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LightWelcome from 'assets/images/Image_Light.png';
import DarkWelcome from 'assets/images/Image_Robot_Dark.png';
import { useTheme } from '@mui/material/styles';

interface WelcomeDialogProps {
    open: boolean;
    onClose: () => void;
}

const WelcomeDialog: React.FC<WelcomeDialogProps> = ({ open, onClose }) => {
    const theme = localStorage.getItem('theme') || 'light';

    useEffect(() => {
        if (open) {
            // Set a timer to automatically close the dialog after 10 seconds
            const timer = setTimeout(() => {
                onClose(); // Trigger the onClose callback to close the dialog
            }, 3000); // 10 seconds

            // Cleanup timer if component unmounts before timer completes
            return () => clearTimeout(timer);
        }
    }, [open, onClose]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    boxShadow: 'none', // Remove the default box shadow
                    backgroundColor: 'var(--background-color-chat-container)', // Set background color to white
                    width: '90%', // Width 90% for responsiveness
                    maxWidth: '600px', // Maximum width
                    margin: '0 auto', // Center the dialog
                    padding: 2, // Padding inside the dialog
                    overflow: 'hidden', // Hide overflow
                    position: 'relative', // Positioning context for responsive styling
                    '@media (max-width:600px)': {
                        width: '90%', // Ensure 90% width on small screens
                        padding: 1, // Adjust padding for small screens
                    },
                },
            }}
            BackdropProps={{
                style: {
                    opacity: 0, // Make the backdrop invisible
                },
            }}
        >
            <DialogTitle
                sx={{
                    position: 'relative', // Ensure positioning for the close button
                    paddingBottom: '0 !important', // Remove extra padding to align close button
                    textAlign: 'center', // Center align text
                    fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: 'Roboto',
                        fontSize: { xs: '20px', sm: '24px' }, // Responsive text size
                        fontWeight: 'bold', // Makes the text bold
                        background: 'linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline-block', // Ensures the text takes gradient properly and can be centered
                    }}
                >
                    Welcome to AI Chatbot StockTraders
                </Typography>

                {/* <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: { xs: 4, sm: 8 }, // Responsive positioning
                        top: { xs: 4, sm: 8 }, // Responsive positioning
                    }}
                >
                    <CloseIcon />
                </IconButton> */}
            </DialogTitle>
            <DialogContent
                sx={{
                    textAlign: 'center',
                    padding: { xs: 1, sm: 2 }, // Responsive padding
                }}
            >
                <Typography
                    sx={{
                        mt: 2.5,
                        fontSize: { xs: '8px', sm: '10px'}, 
                        color: 'var(--primary-text-color)', // Responsive font size
                        textAlign: 'center', // Center the text
                    }}
                >
                    Based on the AI financial solution platform, will provide you
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: '8px', sm: '10px'},
                        color: 'var(--primary-text-color)', // Responsive font size
                        textAlign: 'center', // Center the text
                    }}
                >
                    with timely and accurate advice
                </Typography>
                <img
                    src={theme === 'dark' ? DarkWelcome : LightWelcome}
                    alt="Welcome"
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        maxWidth: '400px', // Max width for the image
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default WelcomeDialog;