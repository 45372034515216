export type Language = 'en' | 'vi';

export const labels = {
  en: {
    signIn: 'Sign In',
    username: 'Username',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot your password?',
    or: 'or',
    noAccount: 'Don’t have an account?',
    register: 'Register',
    enterUsername: 'Enter Username', // Added
    enterPassword: 'Enter Password',  // Added
    invalidEmail: 'Invalid email address', // Added
    passwordLength: 'Password must be at least 8 characters', // Added
    isRequired: 'is required', // Added
    completeRecaptcha: 'Please complete the reCAPTCHA', // Added
    registrationSuccess: 'Registration successful!', // Added
    registrationFailed: 'Registration failed. Please try again.', // Added
    fullName: 'Full Name', // Added
    phoneNumber: 'Phone Number', // Added
    email: 'Email Address', // Added
    signUp: 'Sign Up',
    doneAccount: 'You already have an account?',
    resetPassword: 'Reset Password',
    send: 'Send',
    resetToken: 'Reset Token',
    newPassword: 'New Password',
    resetSuccess: 'Your password has been reset successfully. You can now log in with your new password.',
    successMessage: "Your registration was successful! You will be redirected shortly.",
    registrationSuccessMessage: "Registration Successful!",
    registrationSuccessEmail: "Thank you. We have sent you email to {email}\n Please check your inbox to activate your account.",
    loading: 'Loading...',
    updateAvatarFailed: 'Failed to update avatar',
    passwordMismatch: "New passwords don't match",
    passwordChangeSuccess: 'Password changed successfully',
    passwordChangeFailed: 'Failed to change password: ',
    myAccount: 'My Account',
    profilePicture: 'Profile Picture',
    uploadNewPicture: 'Upload New Picture',
    userInformation: 'User Information',
    name: 'Name',
    pricing: 'Pricing', // Thêm dòng này
    logOut: 'Log Out', // Thêm dòng này
    userManagement: 'User Management', // Thêm dòng này
    messageManagement: 'Message Management', // Thêm dòng này
    userLevel: 'User Level',
    messageLimit: 'Message Limit',
    messagesToday: 'Messages Today',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    confirmNewPassword: 'Confirm New Password',
    accountPackage :'Account Package',
    free: 'Free',
    paid: 'Paid',
    sendResetPasswordSuccess:"We have sent a password recovery instructions to your email.",
    titleResetPasswordSuccess:"Check your email",
    usernameExists: 'Username already exists',
  },
  vi: {
    signIn: 'Đăng nhập',
    username: 'Tên đăng nhập',
    password: 'Mật khẩu',
    rememberMe: 'Ghi nhớ tôi',
    forgotPassword: 'Quên mật khẩu?',
    or: 'hoặc',
    noAccount: 'Chưa có tài khoản?',
    register: 'Đăng ký',
    enterUsername: 'Nhập tên đăng nhập', // Added
    enterPassword: 'Nhập mật khẩu',  // Added
    invalidEmail: 'Địa chỉ email không hợp lệ', // Added
    passwordLength: 'Mật khẩu phải có ít nhất 8 ký tự', // Added
    isRequired: 'là bắt buộc', // Added
    completeRecaptcha: 'Vui lòng hoàn tất reCAPTCHA', // Added
    registrationSuccess: 'Đăng ký thành công!', // Added
    registrationFailed: 'Đăng ký thất bại. Vui lòng thử lại.', // Added
    fullName: 'Họ và tên', // Added
    phoneNumber: 'Số điện thoại', // Added
    email: 'Địa chỉ email', 
    signUp:'Đăng ký',// Added
    doneAccount:"Bạn đã có tài khoản?",
    resetPassword: 'Đặt lại mật khẩu',
    send: 'Gửi',
    resetToken: 'Mã đặt lại',
    newPassword: 'Mật khẩu mới',
    resetSuccess: 'Mật khẩu của bạn đã được đặt lại thành công. Bạn có thể đăng nhập bằng mật khẩu mới của mình.',
    registrationSuccessMessage: "Đăng ký thành công!",
    registrationSuccessEmail: "Cảm ơn bạn. Chúng tôi đã gửi email đến {email}.\n Vui lòng kiểm tra hộp thư đến của bạn để kích hoạt tài khoản.",
    loading: 'Đang tải...',
    updateAvatarFailed: 'Cập nhật ảnh đại diện không thành công',
    passwordMismatch: 'Mật khẩu mới không khớp',
    passwordChangeSuccess: 'Đổi mật khẩu thành công',
    passwordChangeFailed: 'Không thể đổi mật khẩu: ',
    myAccount: 'Tài Khoản Của Tôi',
    profilePicture: 'Ảnh Đại Diện',
    uploadNewPicture: 'Tải Ảnh Mới',
    userInformation: 'Thông Tin Người Dùng',
    name: 'Tên',
    pricing: 'Giá', // Thêm dòng này
    logOut: 'Đăng Xuất', // Thêm dòng này
    userManagement: 'Quản Lý Người Dùng', // Thêm dòng này
    messageManagement: 'Quản Lý Tin Nhắn', // Thêm dòng này
    userLevel: 'Cấp người dùng',
    messageLimit: 'Giới hạn tin nhắn',
    messagesToday: 'Tin nhắn trong ngày',
    changePassword: 'Đổi Mật Khẩu',
    currentPassword: 'Mật Khẩu Hiện Tại',
    confirmNewPassword: 'Xác Nhận Mật Khẩu Mới',
    accountPackage :'Gói tài khoản',
    free: 'Miễn phí',
    paid: 'Có phí',
    sendResetPasswordSuccess:"Chúng tôi đã gửi hướng dẫn khôi phục mật khẩu đến email của bạn. Vui lòng kiểm tra email.",
    titleResetPasswordSuccess:"Kiểm tra email",
    usernameExists: 'Tên đăng nhập đã tồn tại',
  },
};
