import React from 'react';

interface ISentIcon {
  width?: string | number;
  height?: string | number;
}

const SentIcon: React.FC<ISentIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3894 3H4.61094C2.33933 3 1.1554 5.63548 2.6962 7.26234L5.78568 10.5244C6.23 10.9935 6.47673 11.6086 6.47673 12.247V18.4553C6.47673 20.8735 9.61615 21.9233 11.1394 20.0145L21.4463 7.09894C22.7775 5.43071 21.5578 3 19.3894 3Z"
        fill="url(#paint0_linear_619_8575)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_619_8575"
          x1="2"
          y1="12"
          x2="18.0469"
          y2="19.9646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6D92E" />
          <stop offset="0.5" stop-color="#76D300" />
          <stop offset="1" stop-color="#2BD58D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SentIcon;
