import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import fire from "assets/images/fire.png";
import check from "assets/images/check.png";
import flash from "assets/images/flash.png";

interface CardPriceItemProps {
  title: string;
  price: string;
  originalPrice?: string;
  salePrice: string;
  queryText: string;
  limitedQuery: string;
  features: string[];
  buttonText: string;
  highlightFeatures?: string[];
  public?: string;
  isPhoBien: boolean;
}

const StyledButton = styled(Button)`
  && {
    /* Giữ nguyên logic cho isPhoBien là true */
    background: ${(props: any) =>
      props.isPhoBien
        ? "linear-gradient(114deg, #c6d92e 15.44%, #76d300 50%, #2bd58d 84.56%)"
        : "#0b1013"};

    /* Áp dụng màu chữ solid hoặc gradient */
    color: ${(props: any) => (props.isPhoBien ? "#0b1013" : "#C6D92E")};
    background-clip: ${(props: any) =>
      props.isPhoBien ? "border-box" : "text"};
    -webkit-background-clip: ${(props: any) =>
      props.isPhoBien ? "border-box" : "text"};
    -webkit-text-fill-color: ${(props: any) =>
      props.isPhoBien ? "#0b1013" : "#C6D92E"};

    position: relative;
    overflow: hidden;

    /* Xóa gradient text khi isPhoBien là false */
    span {
      background: none;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: inherit;
    }
  }
`;

const CardPriceItem: React.FC<CardPriceItemProps> = ({
  title,
  price,
  originalPrice,
  salePrice,
  queryText,
  limitedQuery,
  features,
  buttonText,
  highlightFeatures = [],
  public: publicText,
  isPhoBien,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: "#111d25",
        padding: "1px",

        ...(isPhoBien && {
          backgroundImage:
            "linear-gradient(114deg, #c6d92e 15.44%, #76d300 50%, #2bd58d 84.56%)",
          "& > div": {
            borderRadius: "12px",
            backgroundColor: "#111d25",
            height: "100%",
            width: "100%",
          },
        }),
      }}
    >
      <CardHeader
        title={
          <Box
            sx={{ display: "flex", alignItems: "center", minHeight: "56px" }}
          >
            <Typography
              component="div"
              fontWeight="bold"
              color="primary"
              style={{ color: "white", whiteSpace: "nowrap", fontSize: "1rem" }}
            >
              {title}
            </Typography>
          </Box>
        }
        action={
          publicText && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={fire}
                alt="Fire"
                style={{ width: 16, height: 16, marginRight: 4 }}
              />
              <Typography
                variant="caption"
                component="div"
                fontWeight="bold"
                sx={{
                  fontSize: "14px",
                  background:
                    "linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {publicText}
              </Typography>
            </Box>
          )
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingLeft: "16px",
          paddingRight: "16px",
          fontWeight: "bold",
          mt: "-20px",
        }}
      >
        <Typography
          variant="body1"
          component="div"
          color="textPrimary"
          sx={{ color: "white", fontSize: "1.5rem" }}
        >
          {price}
        </Typography>
        {originalPrice && (
          <Typography
            variant="body1"
            component="div"
            sx={{
              textDecoration: "line-through",
              color: "var(--Text-text-placeholder)",
              fontSize: "16px",
            }}
          >
            {originalPrice}
          </Typography>
        )}
      </Box>

      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          fontWeight="bold"
          sx={{
            color: "var(--Button-button-background-primary)",
            textAlign: "center",
            fontSize: "0.875rem",
          }}
        >
          {salePrice}
        </Typography>
        <StyledButton variant="contained" fullWidth isPhoBien={isPhoBien}>
          {buttonText}
        </StyledButton>
        <Box sx={{ mt: "15px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={flash}
              alt="Flash"
              style={{ width: 16, height: 16, marginRight: 4 }}
            />
            <Typography variant="subtitle2" component="div" fontWeight="bold">
              {queryText}
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            component="div"
            color="var(--Button-button-background-primary)"
            sx={{
              fontSize: "12px",
              paddingLeft: "20px",
            }}
          >
            {limitedQuery}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {features.map((feature, index) => (
              <li key={index} style={{ marginBottom: "12px" }}> 
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={check}
                    alt="Check"
                    style={{ width: 18, height: 18, marginRight: 6 }} /* Tăng kích thước icon và marginRight */
                  />
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      fontSize: "14px", // Tăng kích thước font từ 12px lên 14px
                      color: "white",
                    }}
                  >
                    {feature}
                  </Typography>
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      </CardContent>
    </Box>
  );
};

export default CardPriceItem;