import React, { useState, useEffect } from 'react';

import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { API_URL } from '../../api/client';

import FormContainer from 'components/Form/FormContainer';
import {
  Container,
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Button,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type FormValues = {
  [key: string]: string;
};

function SetupPassword() {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<FormValues>({});

  const auth = useAuth();
  const navigate = useNavigate();

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
      }));
    }
  };

  const handleChange = (
    e: React.FocusEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (value.trim() !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }

    setValue(value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };

  // TODO: handle comparing password
  const handleSubmit = async (e: any) => {
    e.preventDefault();
  };

  return (
    <FormContainer>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: 'var(--Text-text-primary, #E7E8E8)',
              textAlign: 'center',
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Roboto',
              fontSize: '32px',
              fontStyle: 'normal',
              fontWeight: 600,
              letterSpacing: '2px',
            }}
          >
            Create new Password
          </Typography>
          <Box
            sx={{
              mt: 2,
              '& .css-a2fed3-MuiFormControl-root-MuiTextField-root': {
                marginTop: '3px',
              },
              '& .MuiInputBase-root': {
                backgroundColor: 'transparent !important',
                borderRadius: '8px',
                color: 'var(--primary-text-color)',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--General-Stroke) !important',
                '&:hover': {
                  borderColor: 'var(--General-Stroke) !important',
                },
                '&:focus-within': {
                  borderColor: 'var(--General-Stroke) !important',
                },
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: 'var(--primary-text-color)',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '100',
                  lineHeight: '16px',
                  letterSpacing: '0.15px',
                }}
              >
                New password
              </Typography>
              <TextField
                placeholder="Enter Password"
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                helperText={errors.password}
                onBlur={handleBlur}
                onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleChange(e, setPassword)
                }
                error={!!errors.password}
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: 'transparent !important',
                    borderRadius: '8px',
                    color: 'var(--primary-text-color)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--General-Stroke) !important',
                    '&:hover': {
                      borderColor: 'var(--General-Stroke) !important',
                    },
                    '&:focus-within': {
                      borderColor: 'var(--General-Stroke) !important',
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{
                          color: 'var(--General-Stroke)',
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  color: 'var(--primary-text-color)',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '100',
                  lineHeight: '16px',
                  letterSpacing: '0.15px',
                }}
              >
                Confirm password
              </Typography>
              <TextField
                placeholder="Re-enter your password"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                autoComplete="current-password"
                value={confirmPassword}
                helperText={errors.confirmPassword}
                onBlur={handleBlur}
                onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleChange(e, setConfirmPassword)
                }
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                        sx={{
                          color: 'var(--General-Stroke)',
                        }}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{
                mt: 2,
                borderRadius: '8px',
                background: 'var(--Color-Style-Primary-Gradient-Primary)',
                display: 'flex',
                height: '55px',
                padding: '8px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                alignSelf: 'stretch',
                color: 'var(--Button-button-label-primary)',
                textAlign: 'center',
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
                '&:hover': {
                  background:
                    'var(--Color-Style-Primary-Gradient-Primary-hover)',
                },
              }}
            >
              Change password
            </Button>
          </Box>
        </Box>
      </Container>
    </FormContainer>
  );
}

export default SetupPassword;
