import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, useTheme } from '@mui/material';
import FormContainer from 'components/Form/FormContainer';
import { useLanguage } from 'contexts/LanguageContext';
import { labels } from './languageLabels';
import './SuccessRegister.scss'; // Import your CSS file

function SuccessRegister() {
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme(); // Use theme hook to access theme properties
  const currentLabels = labels[language] || labels['en'];

  const email = location.state?.email || '';

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 2000); // 30 minutes

    return () => clearTimeout(timer);
  }, [navigate]);

  // Format the success email message with a mailto link and line breaks
  const successEmailMessage = currentLabels.registrationSuccessEmail
    .replace('{email}', `<a href="mailto:${email}" style="color: #1E88E5; text-decoration: none;">${email}</a>`)
    .replace(/\n/g, '<br />');

  return (
    <FormContainer>
      <Container component="main" maxWidth="xs">
        <Box
          textAlign="center"
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center" // Center content horizontally
        >
          {/* Use a div with background-image instead of img */}
          <div
            className="theme-image"
            style={{
              width: '80px', // Adjust width as needed
              height: '80px', // Adjust height as needed
              marginBottom: '20px', // Space between image and text
              borderRadius: '23%', // Make the image circular
              backgroundSize: 'auto', // Ensure the image covers the entire div
              backgroundPosition: 'center', // Center the image
            }}
          />
          <Typography
            variant="h5"
            style={{
              background: 'linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline', // Ensure the text takes gradient properly
            }}
          >
            {currentLabels.registrationSuccessMessage}
          </Typography>
          {email && (
            <Typography
              variant="body1"
              mt={2}
              sx={{
                fontSize: '14px', // Adjust font size as needed
                color: 'grey.400', // Light gray color for dark mode
                '& a': {
                  color: '#1E88E5', // Custom color for the email link
                  textDecoration: 'none', // Optional: remove underline from links
                },
              }}
              dangerouslySetInnerHTML={{ __html: successEmailMessage }}
            />
          )}
        </Box>
      </Container>
    </FormContainer>
  );
}

export default SuccessRegister;
