import React, { useState, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import { API_URL } from 'api/client';
import {
  Typography,
  TextField,
  Box,
  FormControl,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  InputLabel,
  MenuItem,
  Container,
  Checkbox,
  ListItemText,
  Paper,
  IconButton,
  SelectChangeEvent,
  Card,
  CardContent,
  Grid,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  color: theme.palette.text.primary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.text.secondary,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.text.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
}));

interface IMessage {
  _id: string;
  session_id: string;
  role: string;
  content: string;
  user_id: string;
  created_at: string;
}

interface IGroupedMessages {
  [key: string]: IMessage[];
}

interface ISearchParams {
  keyword: string;
  sort_by: string;
  sort_order: 'asc' | 'desc';
  page: number;
  per_page: number;
}

interface IFilters {
  date_start: string;
  date_end: string;
  user_level: number[];
}

interface IMessageStatistics {
  total_messages: number;
  total_messages_today: number;
  total_good_ratings: number;
  total_bad_ratings: number;
}

function MessageManagement() {
  const [groupedMessages, setGroupedMessages] = useState<IGroupedMessages>({});
  const [searchParams, setSearchParams] = useState<ISearchParams>({
    keyword: '',
    sort_by: 'created_at',
    sort_order: 'desc',
    page: 1,
    per_page: 100,
  });
  const [filters, setFilters] = useState<IFilters>({
    date_start: '',
    date_end: '',
    user_level: [],
  });
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [messageStatistics, setMessageStatistics] = useState<IMessageStatistics | null>(null);
  const [expandedSessions, setExpandedSessions] = useState<string[]>([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    searchMessages();
    fetchMessageStatistics();
  }, [searchParams, filters]);

  const searchMessages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${API_URL}/search-messages`,
        {
          ...searchParams,
          ...filters,
        },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      if (response.data && Array.isArray(response.data.messages)) {
        const grouped = response.data.messages.reduce((acc: IGroupedMessages, message: IMessage) => {
          if (!acc[message.session_id]) {
            acc[message.session_id] = [];
          }
          acc[message.session_id].push(message);
          return acc;
        }, {});
        setGroupedMessages(grouped);
        setTotalPages(response.data.total_pages);
      } else {
        setError('Invalid response format from server');
      }
    } catch (error) {
      setError('Error searching messages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessageStatistics = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/message-statistics`,
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      setMessageStatistics(response.data);
    } catch (error) {
      console.error('Error fetching message statistics:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleSort = (field: string) => {
    setSearchParams({
      ...searchParams,
      sort_by: field,
      sort_order: searchParams.sort_order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setSearchParams({ ...searchParams, page: newPage });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleUserLevelChange = (e: SelectChangeEvent<number[]>) => {
    setFilters({ ...filters, user_level: e.target.value as number[] });
  };

  const handleExpandSession = (sessionId: string) => {
    setExpandedSessions(prev => 
      prev.includes(sessionId) 
        ? prev.filter(id => id !== sessionId)
        : [...prev, sessionId]
    );
  };

  return (
    <StyledContainer maxWidth="lg">
      {/* Thêm nút Quay lại ở đầu trang */}
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          mb: 2,
          background: 'var(--Color-Style-Primary-Gradient-Primary)',
          '&:hover': {
            background: 'var(--Color-Style-Primary-Gradient-Primary-hover)',
          },
        }}
      >
        Quay lại Chatbot
      </Button>

      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
        Message Management
      </Typography>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}>
            Message Statistics
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">Total Messages:</Typography>
              <Typography variant="h6">{messageStatistics?.total_messages || 0}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">Messages Today:</Typography>
              <Typography variant="h6">{messageStatistics?.total_messages_today || 0}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">Good Ratings:</Typography>
              <Typography variant="h6">{messageStatistics?.total_good_ratings || 0}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body2">Bad Ratings:</Typography>
              <Typography variant="h6">{messageStatistics?.total_bad_ratings || 0}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box mb={3}>
        <StyledTextField
          name="keyword"
          value={searchParams.keyword}
          onChange={handleInputChange}
          placeholder="Search messages"
          variant="outlined"
          fullWidth
          margin="normal"
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton onClick={searchMessages}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="date_start"
              label="Start Date"
              type="date"
              value={filters.date_start}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="date_end"
              label="End Date"
              type="date"
              value={filters.date_end}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal" size="small">
          <InputLabel id="user-level-label">User Level</InputLabel>
          <Select
            labelId="user-level-label"
            multiple
            value={filters.user_level}
            onChange={handleUserLevelChange}
            renderValue={(selected) => (selected as number[]).join(', ')}
            label="User Level"
          >
            {[0, 1, 2, 3, 4].map((level) => (
              <MenuItem key={level} value={level}>
                <Checkbox checked={filters.user_level.indexOf(level) > -1} />
                <ListItemText primary={`Level ${level}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Session ID</TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Role</TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>User ID</TableCell>
                  <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>Timestamp</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(groupedMessages).map(([sessionId, messages]) => (
                  <React.Fragment key={sessionId}>
                    <TableRow>
                      <TableCell>{sessionId}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{messages[0].role}</TableCell>
                      <TableCell>{messages[0].content.substring(0, 50)}...</TableCell>
                      <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{messages[0].user_id}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{new Date(messages[0].created_at).toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleExpandSession(sessionId)}>
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {expandedSessions.includes(sessionId) && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Table size="small">
                            <TableBody>
                              {messages.map((message) => (
                                <TableRow key={message._id}>
                                  <TableCell>{message.role}</TableCell>
                                  <TableCell>{message.content}</TableCell>
                                  <TableCell>{message.user_id}</TableCell>
                                  <TableCell>{new Date(message.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination 
              count={totalPages} 
              page={searchParams.page} 
              onChange={handlePageChange} 
              color="primary"
              size="small"
            />
          </Box>
        </>
      )}
    </StyledContainer>
  );
}

export default MessageManagement;