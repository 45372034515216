import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Define types for the props
interface NoticeLimitMessProps {
  open: boolean;
  onClose: () => void;
}

const NoticeLimitMess: React.FC<NoticeLimitMessProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: '#18191a',
          color: '#fff',
          borderRadius: '8px',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '450px' }, // Responsive width
          height: { xs: 'auto', sm: '300px', md: '250px' }, // Responsive height
          padding: '16px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)', // Add box shadow
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle
        sx={{
          position: 'relative',
          color: 'inherit',
          fontSize: { xs: '16px', sm: '18px', md: '20px' }, // Responsive font size
          fontWeight: 'bold',
          textAlign: 'center',
          paddingBottom: '16px',
        }}
      >
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: '#fff',
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          color: 'inherit',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingTop: '0px',
          paddingBottom: '16px',
        }}
      >
        <Typography sx={{ fontSize: { xs: '18px', sm: '20px', md: '22px' } }}>
          Bạn đã hết lượt hỏi ngày hôm nay :(
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
          Hãy nâng cấp gói để trải nghiệm tốt hơn
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default NoticeLimitMess;
