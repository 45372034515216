import React from 'react';

interface ISeparator {
  title?: string;
}

const Separator: React.FC<ISeparator> = ({ title }) => {
  return (
    <div className="separator">
      <div className="line"></div>
      {title && <span className="text">{title.toUpperCase()}</span>}
      <div className="line"></div>
    </div>
  );
};

export default Separator;
