import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography } from '@mui/material';
import FormContainer from 'components/Form/FormContainer';
import { useLanguage } from 'contexts/LanguageContext';
import { labels } from './languageLabels';

function Waitlist() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const currentLabels = labels[language] || labels['en'];

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <FormContainer>
      <Container component="main" maxWidth="xs">
        <Box
          textAlign="center"
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant="h5"
            style={{
              background: 'linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline',
            }}
          >
            Bạn đang nằm trong danh sách chờ, chúng tôi sẽ kích hoạt tài khoản của bạn trong thời gian sớm nhất. Hãy đợi nhé!
          </Typography>
        </Box>
      </Container>
    </FormContainer>
  );
}

export default Waitlist;