import React, { useState } from 'react';

import useAuth from '../../hooks/useAuth';

import axios from 'axios';
import { API_URL } from '../../api/client';

import { Container, Typography, TextField, Button } from '@mui/material';

function CreateSuperAdmin() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}/create-super-admin`,
        {
          username,
          password,
        },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        },
      );
      alert('Super admin created successfully');
      setUsername('');
      setPassword('');
    } catch (error: any) {
      console.error('Error creating super admin:', error);
      alert(
        'Failed to create super admin: ' + error.response?.data?.detail ||
          error.message,
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Create Super Admin
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create Super Admin
        </Button>
      </form>
    </Container>
  );
}

export default CreateSuperAdmin;
