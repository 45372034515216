import React, { useState, useEffect } from 'react';

interface TypewriterProps {
  text: string;
  speed: number;
  onComplete?: () => void; // Optional callback when animation completes
}

const cleanText = (text: string | undefined): string => {
  return typeof text === 'string'
    ? text.replace(/【.*?】/g, '').replace(/†source/g, '')
    : ''; // Default to empty string if not a string
};

const Typewriter: React.FC<TypewriterProps> = ({ text, speed, onComplete }) => {
  const [displayText, setDisplayText] = useState<string>('');
  const [index, setIndex] = useState<number>(0);

  const defaultMessage = "Hiện tại, tôi không có dữ liệu về thông tin này. Bạn có muốn biết thêm thông tin gì không?";
  const cleanedText = cleanText(text);
  const textToDisplay = cleanedText.length > 0 ? cleanedText : defaultMessage;

  useEffect(() => {
    if (index < textToDisplay.length) {
      const timer = setTimeout(() => {
        setDisplayText(prev => prev + textToDisplay[index]);
        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete(); // Call the callback when text animation completes
    }
  }, [index, textToDisplay, speed, onComplete]);

  return (
    <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {displayText}
    </div>
  );
};

export default Typewriter;
