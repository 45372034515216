import axios from 'axios';
import { useInfiniteQuery } from 'react-query';

import { API_URL } from '../api/client';

export interface IChatSession {
  id: string;
  first_message: string | null;
}

export interface IChatSessionsResponse {
  data: IChatSession[];
  has_more: boolean;
  page: number;
  history: any;
}

const SESSIONS_PER_PAGE = 20;

const fetchSessions = async ({
  pageParam = 1,
}): Promise<IChatSessionsResponse> => {
  const userJson = localStorage.getItem('user');
  const token = userJson ? JSON.parse(userJson).access_token : null;
  // console.log("token: "+token);

  // const token = localStorage.getItem('token');
  const response = await axios.get(
    `${API_URL}/chat-history?page=${pageParam}&limit=${SESSIONS_PER_PAGE}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  
  console.log(response.data) ;
  return response.data;
};

export const useChatSessions = () => {
  return useInfiniteQuery<IChatSessionsResponse, Error>(
    'chat-sessions',
    fetchSessions,
    {
      getNextPageParam: (lastPage) =>
        lastPage.has_more ? lastPage.page + 1 : undefined,
    },
  );
};
