import React, { memo } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface IChat {
  id: string;
  first_message: string | null;
  created_at: string;
  hidden: boolean;
}

export interface IChatHistoryItemProps {
  chat: IChat;
  activeChatId?: string | null;
  loadChat: (id: string) => void;
  hideChat: (id: string) => void;
  hiddenChats: string[]; // Add hiddenChats prop
}

const MemoizedChatHistoryItem: React.FC<IChatHistoryItemProps> = memo(
  ({ chat, activeChatId, loadChat, hideChat, hiddenChats }) => {
    const isActive = chat.id === activeChatId;
    const isHidden = hiddenChats.includes(chat.id);

    if (isHidden) {
      return null; // Hide the chat item if it's in hiddenChats
    }

    return (
      <Box
        onClick={() => {
          loadChat(chat.id);
          //TODO: close left side bar if mobile
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: isActive
            ? "var(--text-history-chat)"
            : "transparent",
          borderRadius: "5px",
          padding: "5px",
          width: "100%",
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "var(--text-history-chat)",
            "& .MuiIconButton-root": {
              opacity: 1, // Show button on hover
            },
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            fontSize: "0.9em",
          }}
        >
          {chat.first_message
            ? chat.first_message.length > 30
              ? chat.first_message.substring(0, 30) + "..."
              : chat.first_message
            : "New chat"}
        </Box>

        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevent click event from bubbling up
            hideChat(chat.id); // Call function to hide chat
          }}
          sx={{
            padding: "2px",
            color: "red", // Thay đổi màu thành đỏ
            opacity: 0, // Hide button by default
            transition: "opacity 0.3s ease", // Smooth transition for opacity
            '&:hover': {
              color: "darkred", // Tùy chọn: thêm màu khi hover
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  }
);

export default MemoizedChatHistoryItem;
