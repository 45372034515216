import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import Tooltip from "@mui/material/Tooltip";

import { useChatSessions } from "hooks/useChatSession";
import { useInView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useMobile from "hooks/useMobile";
import useAuth from "hooks/useAuth";

import axios from "axios";
import { BASE_URL } from "api/client";

import { isToday, isYesterday, parseISO, format } from "date-fns";
import { io } from "socket.io-client";
import MemoizedChatHistoryItem, { IChat } from "functions/Chat/ChatHistoryItem";
import Typewriter from "./TypeWriter";
import { keyframes } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  IconButton,
  Icon,
  Avatar,
  List,
  ListItem,
  Popover,
  Dialog,
} from "@mui/material";

import SentIcon from "assets/svgs/sent";
import StocktradersBot from "assets/svgs/stocktradersBot";

import DummyAvatar from "assets/images/dummy-avatar.png";
import StockBot from "assets/images/stockbot.png";
import CompanyIcon from "assets/images/company-icon.png";

import HelpPolygon from "assets/images/Vector.png";

import ThemeToggle from "./ThemeToggle";
import LightWelcome from "../assets/images/Image_Light.png";
import DarkWelcome from "../assets/images/Image_Robot_Dark.png";

import Info from "assets/images/information-polygon.png";
import Suggestions from "./Suggestions";
import MessageActions from "./MessageRate";
import WelcomeDialog from "functions/Authentication/WelcomeLogin";
import NoticeLimitMess from "./NoticeLimitMess";
import PricingOptions from "./PriceTable";
import { API_URL } from "api/client";
import { useCreateNewChat } from "hooks/userCreateNewChat";
interface GroupedChats {
  [key: string]: IChat[];
}

const ellipsisAnimation = keyframes`
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
  100% { content: ''; }
`;

const user_id = localStorage.getItem("user-id");

function MainApp(): React.ReactNode {
  const [messages, setMessages] = useState<any[]>([]);
  const [input, setInput] = useState<string>("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [leftSidebarWidth, setLeftSidebarWidth] = useState<number>(250);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [activeChatId, setActiveChatId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [latestBotMessageId, setLatestBotMessageId] = useState(null);
  const [chatHistoryHeight, setChatHistoryHeight] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const calculateHeight = () => {
      const headerElement = document.querySelector(".header") as HTMLElement;
      const footerElement = document.querySelector(".footer") as HTMLElement;
      const headerHeight = headerElement?.offsetHeight || 0;
      const footerHeight = footerElement?.offsetHeight || 0;
      setChatHistoryHeight(window.innerHeight - (headerHeight + footerHeight));
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  const pricingOptionsRef = useRef(null);

  const theme = useTheme();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [isLeftBarShow, setIsLeftBarShow] = useState<boolean>(!isMobile);

  const [showWelcomeImage, setShowWelcomeImage] = useState(true);
  const [isTypewriterComplete, setIsTypewriterComplete] = useState(false);
  const [isPriceOptionsOpen, setIsPriceOptionsOpen] = useState<boolean>(false);

  const handleTypewriterComplete = () => {
    setIsTypewriterComplete(true);
  };

  const handleOpenPricingDialog = () => {
    setIsPriceOptionsOpen(true);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsLeftBarShow(true);
    } else {
      setIsLeftBarShow((prev) => prev);
    }
  }, [isMobile]);

  useEffect(() => {
    setShowWelcomeImage(true);
    const timer = setTimeout(() => {
      setShowWelcomeImage(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [theme.palette.mode]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const userJson = localStorage.getItem("user");
  const token = userJson ? JSON.parse(userJson).access_token : null;
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false);
  const user_level = userJson ? JSON.parse(userJson).level : null;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: isSessionsLoading,
    isFetchingNextPage,
  } = useChatSessions();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    const socket = io(BASE_URL, {
      transports: ["websocket", "polling"],
      path: "/socket.io/",
      withCredentials: true,
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      const token = localStorage.getItem("access_token");
      if (token) {
        socket.emit("authenticate", { token });
      }
    });

    socket.on("connect_error", (error) => {
      console.error("Socket.IO connection error:", error);
    });

    socket.on("message_update", handleMessageUpdate);

    return () => {
      socket.disconnect();
    };
  }, []);

  const cleanText = (text: string | null): string => {
    const validText = typeof text === "string" ? text : "";

    return validText
      .replace(/【.*?】/g, "")
      .replace(/†source/g, "")
      .replace(/\n/g, "<br/>");
  };

  const convertNewLinesToBreaks = (text: string | null): string => {
    if (typeof text !== "string") return "";

    const singleNewLinesReplaced = text.replace(/\n/g, "<br/>");

    return singleNewLinesReplaced.replace(/(<br\/>\s*){2,}/g, "<br/><br/>");
  };


  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);


  useEffect(() => {
    const updateOnlineStatus = async (status: boolean) => {
      const userJson = localStorage.getItem("user");
      if (userJson) {
        const user = JSON.parse(userJson);
        const access_token = user.access_token;
        try {
          await axios.post(
            `${API_URL}/update-online-status`,
            { status: status },
            {
              headers: { Authorization: `Bearer ${access_token}` },
            }
          );
        } catch (error) {
          console.error("Error updating online status: ", error);
        }
      }
    };

    updateOnlineStatus(true);

    const handleBeforeUnload = () => {
      updateOnlineStatus(false);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      updateOnlineStatus(false);
    };
  }, [auth?.user]);

  const handleMessageUpdate = useCallback((updatedMessage: any) => {
    setMessages((prevMessages) => {
      const messageExists = prevMessages.some(
        (msg) =>
          msg.id === updatedMessage.assistant_message_id ||
          msg.text === updatedMessage.response
      );
      if (!messageExists) {
        return [
          ...prevMessages,
          {
            id: updatedMessage.assistant_message_id,
            text: updatedMessage.response,
            isUser: false,
            isHistorical: false,
          },
        ];
      }
      return prevMessages;
    });

    setSuggestions(updatedMessage.suggested_questions);
    setCurrentSessionId(updatedMessage.session_id);
    setIsLoading(false);
    setLatestBotMessageId(updatedMessage.assistant_message_id);
    setShowSuggestions(true);
  }, []);

  const sendMessage = useCallback(
    async (messageOverride = null) => {
      const messageToSend = messageOverride || input;
      if (messageToSend.trim() === "" || isLoading) return;

      const newUserMessage = {
        id: Date.now(),
        text: messageToSend,
        isUser: true,
        isHistorical: false,
      };
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setInput("");
      setIsLoading(true);
      setShowSuggestions(false);

      const userJson = localStorage.getItem("user");
      if (userJson) {
        const user = JSON.parse(userJson);
        const access_token = user.access_token;

        try {
          const response = await axios.post(
            `${API_URL}/chat`,
            {
              message: messageToSend,
              session_id: currentSessionId,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
          console.log("response data chat" + JSON.stringify(response));
          const botMessage = response.data.response;
          setSuggestions(response.data.suggested_questions);
          setMessages((prevMessages) => {
            const messageExists = prevMessages.some(
              (msg) => msg.text === botMessage
            );
            if (!messageExists) {
              return [
                ...prevMessages,
                {
                  id: response.data.message_id || Date.now(),
                  text: botMessage,
                  isUser: false,
                  isHistorical: false,
                },
              ];
            }
            return prevMessages;
          });
          setIsLoading(false);
          setShowSuggestions(true);
        } catch (error: any) {
          console.error("Error sending message:", error);
          setIsLoading(false);
          console.error(error.response);
          if (
            error.response?.status === 500 &&
            error.response.data.detail === "429: Message limit exceeded"
          ) {
            setOpenNoticeDialog(true);
          }
        }
      }
    },
    [input, isLoading, currentSessionId]
  );

  const rateMessage = useCallback(
    async (messageId: string, rate: any) => {
      try {
        await axios.post(
          `${API_URL}/rate-message`,
          { message_id: messageId, rating: rate },
          {
            headers: { Authorization: `Bearer ${auth?.user.access_token}` },
          }
        );
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === messageId ? { ...msg, rating: rate } : msg
          )
        );
      } catch (error) {
        console.error("Error rating message:", error);
      }
    },
    [auth?.user]
  );
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const loadChat = useCallback(
    async (sessionId: any) => {
      const userJson = localStorage.getItem("user");
      const token = userJson ? JSON.parse(userJson).access_token : null;
      try {
        if (sessionId !== currentSessionId) {
          setActiveChatId(sessionId);
          setCurrentSessionId(sessionId);
          setMessages([]);
          const response = await axios.get(
            `${API_URL}/session-messages/${sessionId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setMessages(
            response.data.messages.map((msg: any) => ({
              id: msg.id,
              text: msg.content,
              isUser: msg.role === "user",
              rating: msg.rating,
              isHistorical: true,
            }))
          );
        }
      } catch (error) {
        console.error("Error loading chat session:", error);
      }
    },
    [currentSessionId, auth?.user]
  );

  const handleSuggestionClick = useCallback(
    (suggestion: any) => {
      setInput(suggestion);
      sendMessage(suggestion);
    },
    [sendMessage]
  );

  const groupChatHistoryByDate = useCallback((history: IChat[]) => {
    const grouped: GroupedChats = {};
    history.forEach((chat: IChat) => {
      const date = parseISO(chat.created_at);
      let key;
      if (isToday(date)) {
        key = "Today";
      } else if (isYesterday(date)) {
        key = "Yesterday";
      } else {
        key = format(date, "MMMM d, yyyy");
      }
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(chat);
    });
    return grouped;
  }, []);

  const [hiddenChats, setHiddenChats] = useState<string[]>([]);

  const hideChat = useCallback(
    async (sessionId: any) => {
      try {
        setHiddenChats((prevHiddenChats) => [...prevHiddenChats, sessionId]);

        await axios.post(
          `${API_URL}/hide-session/${sessionId}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        queryClient.invalidateQueries("sessions");
      } catch (error) {
        console.error("Error hiding chat session:", error);
      }
    },
    [token, queryClient]
  );

  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  useEffect(() => {
    const showWelcomeDialog = localStorage.getItem("showWelcomeDialog");
    if (showWelcomeDialog === "true") {
      setOpenWelcomeDialog(true);

      localStorage.removeItem("showWelcomeDialog");

      const timer = setTimeout(() => {
        setOpenWelcomeDialog(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const { createNewChat } = useCreateNewChat();

  const {
    data: chatSessionsData,
    isLoading: isFetchingSessions,
    refetch,
  } = useChatSessions();

  const handleCreateChat = async () => {
    const sessionId = await createNewChat(token);

    if (sessionId) {
      refetch();
    }
  };

  const bottomOfPanelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bottomOfPanelRef.current) {
      bottomOfPanelRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const MenuIcon = (
    <Button
      sx={{
        minWidth: "auto",
        width: 40,
        height: 40,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        backgroundColor: "var(--Button-button-background-secondary)",
        boxShadow: 2,
        position: "relative",
        "&:hover": {
          backgroundColor: "var(--Button-button-background-primary)",
        },
      }}
    >
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: 0.2,
          backgroundColor: "var(--Text-text-placeholder)",
          position: "absolute",
          top: 8,
          left: 8,
          transform: "rotate(30deg)",
        }}
      />
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: 0.2,
          backgroundColor: "var(--Text-text-placeholder)",
          position: "absolute",
          top: 8,
          right: 8,
          transform: "rotate(30deg)",
        }}
      />
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: 0.2,
          backgroundColor: "var(--Text-text-placeholder)",
          position: "absolute",
          bottom: 8,
          left: 8,
          transform: "rotate(30deg)",
        }}
      />
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: 0.2,
          backgroundColor: "var(--Text-text-placeholder)",
          position: "absolute",
          bottom: 8,
          right: 8,
          transform: "rotate(30deg)",
        }}
      />
    </Button>
  );

  const LeftSideBar = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: {
          xs: "100vw",
          sm: leftSidebarWidth,
        },
        height: "100%",
        borderRight: "1px solid var(--border-right-left-bar)",
        backgroundColor: "var(--General-surface-primary-2)",
        position: {
          xs: "absolute",
          sm: "relative",
          md: "relative",
        },
        transform: {
          xs: "translateX(0)",
          sm: "none",
        },
        transition: "transform 0.3s ease",
        zIndex: 99,
        padding: "3px",
      }}
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Icon
          sx={{
            width: {
              xs: "25px",
              sm: "30px",
              md: "35px",
            },
            height: {
              xs: "25px",
              sm: "30px",
              md: "35px",
            },
            backgroundImage: `url(${CompanyIcon})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: {
              xs: "5px",
              sm: "10px",
            },
          }}
        />
      </Box>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={handleCreateChat}
        sx={{
          mt: 2,
          borderRadius: "8px",
          background: "var(--Color-Style-Primary-Gradient-Primary)",
          display: "flex",
          height: {
            xs: "40px",
            sm: "46px",
          },
          padding: {
            xs: "6px 6px",
            sm: "8px 8px",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: {
            xs: "8px",
            sm: "10px",
          },
          alignSelf: "stretch",
          color: "var(--Button-button-label-primary)",
          textAlign: "center",
          fontSize: {
            xs: "12px",
            sm: "14px",
          },
          fontStyle: "normal",
          fontWeight: 500,
          textTransform: "none",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            width: {
              xs: "90%",
              sm: "80%",
              md: "70%",
            },
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "20px",
                sm: "24px",
                md: "26px",
              },
              fontWeight: 400,
            }}
          >
            +
          </Typography>
        </Box>

        <Typography
          sx={{
            color: "var(--General-surface-primary)",
            fontFamily: "Roboto",
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            fontWeight: 400,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontStyle: "normal",
            textTransform: "none",
          }}
        >
          New Chat
        </Typography>
      </Button>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={() => {
          alert("Function not developed yet!");
        }}
        sx={{
          mt: 2,
          borderRadius: "8px",

          background: "var(--Button-button-background-secondary)",
          display: "flex",
          alignItems: "center",
          height: {
            xs: "40px",
            sm: "46px",
          },
          gap: {
            xs: "8px",
            sm: "10px",
          },
          color: "var(--Button-button-label-primary)",
          textAlign: "center",
          alignSelf: "stretch",
          "&:hover": {
            background: "var(--Button-button-background-secondary)",
          },
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            width: {
              xs: "90%",
              sm: "85%",
              md: "80%",
            },
            justifyContent: "flex-start",
          }}
        >
          <StocktradersBot />
        </Box>
        <Typography
          sx={{
            color: "var(--Button-button-background-primary)",
            fontFamily: "Roboto",
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            fontWeight: 400,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontStyle: "normal",
            textTransform: "none",
          }}
        >
          AI StockTraders
        </Typography>
      </Button>

      <Box
        sx={{
          mt: 2,
          mb: "8vh",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          scrollbarWidth: "auto",
          "&::-webkit-scrollbar": {
            display: "auto",
          },
        }}
      >
        {!isSessionsLoading &&
          data?.pages[0]?.history.length > 0 &&
          data?.pages.map((page, i) => {
            const groupedHistory = groupChatHistoryByDate(page.history);
            return (
              <React.Fragment key={i}>
                {Object.entries(groupedHistory).map(([date, chats]) => (
                  <Box key={date} mb={2}>
                    <Typography
                      sx={{
                        color: "var(--Text-text-placeholder)",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Roboto",
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                        },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "157%",
                        letterSpacing: "0.1px",
                      }}
                    >
                      {date}
                    </Typography>
                    <List disablePadding>
                      {chats.map((chat) => (
                        <ListItem key={chat.id} disableGutters>
                          <MemoizedChatHistoryItem
                            chat={chat}
                            activeChatId={activeChatId}
                            loadChat={loadChat}
                            hideChat={hideChat}
                            hiddenChats={hiddenChats}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </React.Fragment>
            );
          })}
        {isFetchingNextPage && <Typography>Loading more...</Typography>}
        <div ref={ref} style={{ height: 20 }} />
      </Box>

      <Box
        sx={{
          display: "flex",
          borderTop: "1.5px solid var(--border-right-left-bar)",
          mb: "auto",
          height: "5vh",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Avatar
            src={DummyAvatar}
            alt="User Avatar"
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "5px",
          }}
        >
          <ThemeToggle />
          <Tooltip title="Thông tin" arrow>
            <IconButton
              onClick={() => {}}
              sx={{
                padding: "4px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar
                src={Info}
                alt="inf"
                sx={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Trợ giúp" arrow>
            <IconButton
              onClick={() => {}}
              sx={{
                padding: "4px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar
                src={HelpPolygon}
                alt="helppolygon"
                sx={{
                  width: "17px",
                  height: "17px",
                  mr: "10px",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              backgroundColor: "var(--General-surface-primary-2)",
              boxShadow: "2",
              p: {
                xs: 1,
                sm: 2,
                md: 3,
              },
              border: "1.5px solid var(--General-Stroke)",
              borderRadius: {
                xs: "12px",
                sm: "16px",
                md: "20px",
              },
              zIndex: 999,
              maxWidth: {
                xs: "90vw",
                sm: "80vw",
                md: "auto",
              },
              overflow: "auto",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              padding: {
                xs: "8px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: {
                  xs: "-10px",
                  sm: "-15px",
                  md: "-20px",
                },
                right: "50%",
                transform: "translateX(50%)",
                backgroundColor: "transparent",
                borderRadius: "50%",
                padding: {
                  xs: "10px",
                  sm: "15px",
                  md: "20px",
                },
                zIndex: 1,
                pb: {
                  xs: "5px",
                  sm: "8px",
                  md: "10px",
                },
              }}
            >
              <Avatar
                src={DummyAvatar}
                alt="User Avatar"
                sx={{
                  width: {
                    xs: "40px",
                    sm: "45px",
                    md: "50px",
                  },
                  height: {
                    xs: "40px",
                    sm: "45px",
                    md: "50px",
                  },
                  border: "2px solid var(--Button-button-label-primary)",
                }}
              />
            </Box>

            <Box
              sx={{
                pt: {
                  xs: 3,
                  sm: 4,
                  md: 5,
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: {
                  xs: 1,
                  sm: 1.5,
                  md: 2,
                },
              }}
            >
              {auth?.user || user_id ? (
                <>
                  {user_level >= 3 ? (
                    <>
                      <Button
                        onClick={() => {
                          navigate("/my-account");
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        My Account
                      </Button>
                      <Button
                        onClick={() => {
                          handleOpenPricingDialog();
                          handleClose();
                          if (isMobile) {
                            setIsLeftBarShow(false);
                          }
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        Pricing
                      </Button>

                      <Button
                        onClick={() => {
                          navigate("/user-management");
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        User Management
                      </Button>
                      <Button
                        onClick={() => {
                          navigate("/message-management");
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        Message Management
                      </Button>
                      <Button
                        onClick={() => {
                          auth?.logout();
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        Logout
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          navigate("/my-account");
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        My Account
                      </Button>
                      <Button
                        onClick={() => handleOpenPricingDialog()}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        Pricing
                      </Button>
                      <Button
                        onClick={() => {
                          auth?.logout();
                          handleClose();
                        }}
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                          },
                          color: "var(--primary-text-color)",
                          width: "100%",
                        }}
                      >
                        Logout
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      navigate("/login");
                      handleClose();
                    }}
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                      },
                      width: "100%",
                      mb: {
                        xs: 1,
                        sm: 1.5,
                        md: 2,
                      },
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/register");
                      handleClose();
                    }}
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                      },
                      width: "100%",
                    }}
                  >
                    Register
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );

  const ChatFrame = (
    <Box
      sx={{
        backgroundColor: "var(--background-color-chat-container)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        margin: "0 auto",
        padding: {
          xs: "10px",
          sm: "0px",
          md: "0px",
        },
        pt: {
          xs: "10px",
          sm: "20px",
          md: "20px",
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          overflowY: "auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          maxWidth: "84%",
          margin: "0 auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },

          "@media (max-width: 600px)": {
            width: "100%",
            maxWidth: "100%",
          },
        }}
      >
        {messages.map((message, index) =>
          message.isUser ? (
            <Box
              key={index}
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                maxWidth: "100%",
                margin: "0 auto",
                flexDirection: "column",

                "@media (max-width: 600px)": {
                  marginLeft: "10px",
                  marginRight: "10px",
                },
              }}
            >
              <div ref={bottomOfPanelRef}></div>
              <Box
                sx={{
                  fontSize: "0.9em",
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                  p: 1,
                  borderRadius: "20px",

                  color: "var(--text-color)",
                  backgroundColor: "var(--Button-button-background-secondary)",
                  marginLeft: "20px",

                  "@media (max-width: 600px)": {
                    marginLeft: "10px",
                    padding: "0.5em",
                  },
                }}
              >
                {message.text}
              </Box>
            </Box>
          ) : (
            <Box
              key={index}
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                flexDirection: "column",
                "@media (max-width: 600px)": {
                  padding: "0 10px",
                },
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Avatar
                  src={StockBot}
                  alt="User Avatar"
                  sx={{
                    width: "32px",
                    height: "32px",
                    "@media (max-width: 600px)": {
                      width: "24px",
                      height: "24px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    ml: 1,
                    "@media (max-width: 600px)": {
                      ml: 0,
                      mt: 1,
                    },
                  }}
                >
                  AI Chatbot StockTraders
                </Typography>
              </Box>
              <Box
                sx={{
                  fontSize: "0.9em",
                  maxWidth: "77%",
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  p: 1,
                  borderRadius: "8px",
                  marginLeft: "32px",

                  "@media (max-width: 600px)": {
                    maxWidth: "100%",
                    marginLeft: 0,
                    p: 0.5,
                  },
                }}
              >
                {message.isHistorical ? (
                  <Typography
                    sx={{
                      fontSize: "inherit",
                      "@media (max-width: 600px)": {
                        fontSize: "0.8em",
                      },
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertNewLinesToBreaks(
                          cleanText(message.text)
                        ),
                      }}
                    />
                    <MessageActions
                      message={message}
                      rateMessage={rateMessage}
                    />
                  </Typography>
                ) : (
                  <Typewriter
                    text={message.text}
                    speed={20}
                    onComplete={handleTypewriterComplete}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "5px",
                    "@media (max-width: 600px)": {
                      justifyContent: "center",
                      mt: 2,
                    },
                  }}
                >
                  {isTypewriterComplete && (
                    <MessageActions
                      message={message}
                      rateMessage={rateMessage}
                    />
                  )}
                </Box>
              </Box>
              <div ref={messagesEndRef} />
              <div ref={bottomOfPanelRef}></div>
            </Box>
          )
        )}
        {isLoading && (
          <Box
            sx={{
              mt: 1,
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              flexDirection: "column",
              "@media (max-width: 600px)": {
                padding: "0 10px",
              },
            }}
          >
            <Box
              sx={{
                mb: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <Avatar
                src={StockBot}
                alt="User Avatar"
                sx={{
                  width: "32px",
                  height: "32px",
                  "@media (max-width: 600px)": {
                    width: "24px",
                    height: "24px",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  "@media (max-width: 600px)": {
                    ml: 0,
                    mt: 1,
                  },
                }}
              >
                AI Chatbot StockTraders
              </Typography>
            </Box>
            <Box
              sx={{
                fontSize: "0.9em",
                maxWidth: "77%",
                wordWrap: "break-word",
                wordBreak: "break-word",
                p: 1,
                borderRadius: "8px",
                marginLeft: "32px",

                "@media (max-width: 600px)": {
                  maxWidth: "100%",
                  marginLeft: 0,
                  p: 0.5,
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  display: "inline-block",
                  "&::after": {
                    content: '""',
                    animation: `${ellipsisAnimation} 1.5s infinite`,
                    display: "inline-block",
                    width: "1em",
                    textAlign: "left",
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      {!isLoading && showSuggestions && isTypewriterComplete && (
        <Box
          sx={{
            width: "80%",
            margin: "0 auto",
            pb: "20px",
            "@media (max-width: 1200px)": {
              width: "90%",
            },
            "@media (max-width: 600px)": {
              width: "95%",
              pb: "10px",
            },
          }}
        >
          <Suggestions
            suggestions={suggestions}
            onSuggestionClick={handleSuggestionClick}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          width: "84%",
          margin: "0 auto",
          pb: "20px",
          "@media (max-width: 1200px)": {
            width: "90%",
          },
          "@media (max-width: 768px)": {
            width: "95%",
            pb: "15px",
            paddingBottom: "calc(env(safe-area-inset-bottom) + 15px)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "16px",
            border: !isLoading
              ? "1px solid var(--Button-button-background-primary)"
              : "1px solid var(--Button-button-background-primary-waiting)",
            backgroundColor: "transparent",
            paddingRight: "30px",
            "@media (max-width: 1200px)": {
              paddingRight: "20px",
            },
            "@media (max-width: 600px)": {
              paddingRight: "10px",
              borderRadius: "12px",
            },
          }}
        >
          <NoticeLimitMess
            open={openNoticeDialog}
            onClose={() => setOpenNoticeDialog(false)}
          />
          <TextField
            fullWidth
            type="text"
            value={input}
            autoComplete="off"
            disabled={isLoading}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && sendMessage()}
            placeholder={suggestions[0] || "Ask me anything..."}
            InputProps={{
              disableUnderline: true,
              sx: {
                color: "var(--Text-text-filled)",
                "&::placeholder": {
                  color: "var(--General-Stroke)",
                  opacity: 1,
                },
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                border: "none",
                "&:hover": {
                  border: "none",
                },
                "&:before, &:after": {
                  display: "none",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
                "&:focus": {
                  outline: "none",
                },
              },
              "& .MuiInputBase-input": {
                "&:focus": {
                  outline: "none",
                },
              },
              "@media (max-width: 600px)": {
                fontSize: "0.8rem",
              },
            }}
          />
          <IconButton
            onClick={() => sendMessage()}
            disabled={isLoading}
            aria-label="send message"
            edge="end"
            sx={{
              height: "100%",
              opacity: isLoading ? 0.2 : 1,
              cursor: isLoading ? "not-allowed" : "pointer",
              "&:hover": {
                backgroundColor: isLoading
                  ? "transparent"
                  : "rgba(0, 0, 0, 0.08)",
              },
              "@media (max-width: 600px)": {
                width: "40px",
                height: "40px",
              },
            }}
          >
            <SentIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "calc(var(--vh, 1vh) * 100 - 20px)", // Sử dụng biến CSS
        paddingLeft: { xs: "0px", sm: "0px" },
        paddingRight: { xs: "0px", sm: "0px" },
        "@media (min-width: 1200px)": {
          maxWidth: "100%",
        },
      }}
    >
      <WelcomeDialog
        open={openWelcomeDialog}
        onClose={() => setOpenWelcomeDialog(false)}
      />

      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          height: "calc(var(--vh, 1vh) * 100 - 20px)", // Sử dụng biến CSS
          paddingLeft: { xs: "0px", sm: "0px" },
          paddingRight: { xs: "0px", sm: "0px" },
          maxWidth: { xs: "100%", lg: "100%" },
        }}
      >
        {isMobile && (
          <IconButton
            onClick={() => {
              setIsLeftBarShow(!isLeftBarShow);
            }}
            sx={{ zIndex: 100, position: "fixed", left: 16, top: 16 }}
          >
            {MenuIcon}
          </IconButton>
        )}
        {isLeftBarShow && LeftSideBar}

        {isPriceOptionsOpen === false ? (
          ChatFrame
        ) : (
          <PricingOptions
            pricingOptionRef={pricingOptionsRef}
            setIsPriceOptionsOpen={setIsPriceOptionsOpen}
          />
        )}
      </Container>
    </Container>
  );
}

export default MainApp;