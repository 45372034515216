import './Header.scss';
import React from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import ThemeToggle from 'components/ThemeToggle';
import companyIcon from '../../assets/images/company-icon.png';
import companyNameSlogan from '../../assets/images/company-name-slogan.png';

const Header: React.FC = () => {
  const { language, setLanguage } = useLanguage(); // Get language and setLanguage from context

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value as 'en' | 'vi');
  };
    
  return (
    <header className="header">
      <div className="header__logo">
        {/* <img src={companyIcon} alt="company-logo" /> */}
        <img src={companyNameSlogan} alt="company-name-slogan" />
      </div>
      <div className="header__actions">
        <select
          value={language}
          onChange={onLanguageChange}
          className="header__language-select"
        >
          <option value="en">English</option>
          <option value="vi">Việt Nam</option>
        </select>
        <ThemeToggle />
      </div>
    </header>
  );
};

export default Header;
