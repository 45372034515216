import React from 'react';

import useAuth from '../../hooks/useAuth';
import { useLocation, Navigate } from 'react-router-dom';
interface ProtectedRouteProps {
    children: React.ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
    const auth = useAuth();
    const location = useLocation();
    const user_id = localStorage.getItem('user-id');

    if (
        !auth?.user &&
        !user_id &&
        location.pathname !== '/login' &&
        location.pathname !== '/register'
    ) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children
}

export default ProtectedRoute;
