import "./Components.scss";
import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

const ThemeToggle: React.FC = () => {
  const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");

  const onToggleTheme = () => {
    const newTheme = currentTheme === "dark" ? "light" : "dark";
    setCurrentTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") || "light";
    setCurrentTheme(storedTheme as "light" | "dark");
    document.documentElement.setAttribute("data-theme", storedTheme);
    const theme =
      document.documentElement.getAttribute("data-theme") || "light";
    setCurrentTheme(theme as "light" | "dark");
  }, []);

  return (
    <Tooltip title="Giao diện" arrow>
      <button onClick={onToggleTheme} className="theme-toggle">
        {currentTheme === "light" ? "☀️" : "🌙"}
      </button>
    </Tooltip>
  );
};

export default ThemeToggle;
