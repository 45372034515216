import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { API_URL } from "../../api/client";
import {
  Card,
  CardContent,
  TextField,
  Select,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Typography,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

import { IUser, IUserStats, IFilters } from "./Interfaces";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

function UserManagement() {
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [changes, setChanges] = useState<{ [key: string]: Partial<IUser> }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isWaitlistEnabled, setIsWaitlistEnabled] = useState<boolean>(false);
  const [userStats, setUserStats] = useState<IUserStats | null>(null);
  const [filters, setFilters] = useState<IFilters>({
    level: [],
    permissions: [],
    status: [],
    online: "",
    activityStart: "",
    activityEnd: "",
  });
  const [tempFilters, setTempFilters] = useState<IFilters>({
    level: [],
    permissions: [],
    status: [],
    online: "",
    activityStart: "",
    activityEnd: "",
  });
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [messageLimits, setMessageLimits] = useState({
    level_0: 100,
    level_1: 100,
  });

  const auth = useAuth();
  const userJson = localStorage.getItem("user");
  const token = userJson ? JSON.parse(userJson).access_token : null;

  const availablePermissions = [
    "noti_branch",
    "stock_signal_real",
    "stock_wave_real",
  ];

  const levelOptions = [
    { value: 0, label: "Free" },
    { value: 1, label: "Paid" },
    { value: 2, label: "CS" },
    { value: 3, label: "Admin" },
    { value: 4, label: "Super Admin" },
  ];

  const statusOptions = [
    { value: "Waitlist", label: "Waitlist" },
    { value: "Active", label: "Active" },
    { value: "Blocked", label: "Blocked" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
    fetchWaitlistStatus();
    fetchUserStats();
    fetchMessageLimits();
  }, [searchTerm, currentPage, filters, sortField, sortOrder]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          ...(searchTerm && { search: searchTerm }),
          ...(sortField && { sort: `${sortField}:${sortOrder}` }),
          ...(filters.level.length > 0 && { level: filters.level.join(",") }),
          ...(filters.permissions.length > 0 && {
            permissions: filters.permissions.join(","),
          }),
          ...(filters.status.length > 0 && {
            status: filters.status.join(","),
          }),
          ...(filters.online !== "" && { online: filters.online }),
          ...(filters.activityStart && {
            activity_start: filters.activityStart,
          }),
          ...(filters.activityEnd && { activity_end: filters.activityEnd }),
          page: currentPage,
          per_page: 100,
        },
      });
      setUsers(response.data.users);
      console.log(JSON.stringify(response));
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const fetchWaitlistStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/waitlist-status`, {
        headers: { Authorization: `Bearer ${auth?.user.access_token}` },
      });
      setIsWaitlistEnabled(response.data.is_enabled);
    } catch (error) {
      console.error("Error fetching waitlist status:", error);
    }
  };

  const fetchUserStats = async () => {
    try {
      const response = await axios.get(`${API_URL}/user-stats`, {
        headers: { Authorization: `Bearer ${auth?.user.access_token}` },
      });
      setUserStats(response.data);
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  const fetchMessageLimits = async () => {
    try {
      const response = await axios.get(`${API_URL}/message-limits`, {
        headers: { Authorization: `Bearer ${auth?.user.access_token}` },
      });
      setMessageLimits(response.data);
    } catch (error) {
      console.error("Error fetching message limits:", error);
    }
  };

  const updateUser = (userId: string, field: string, value: string) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: {
        ...prevChanges[userId],
        [field]: value,
      },
    }));
  };

  const updatePermission = (
    userId: string,
    permission: string,
    value: boolean
  ) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: {
        ...prevChanges[userId],
        permissions: {
          ...(prevChanges[userId]?.permissions || {}),
          [permission]: value,
        },
      },
    }));
  };

  const applyChanges = async () => {
    try {
      const changesArray = Object.entries(changes).map(([id, change]) => ({
        id,
        ...change,
      }));
      const response = await axios.post(`${API_URL}/apply-user-changes`, changesArray, {
        headers: { Authorization: `Bearer ${auth?.user.access_token}` },
      });
      if (response.data.success) {
        alert("Các thay đổi đã được áp dụng thành công");
        setChanges({});
        fetchUsers();
      } else {
        alert("Không thể áp dụng các thay đổi: " + response.data.message);
      }
    } catch (error) {
      console.error("Lỗi khi áp dụng các thay đổi:", error);
      alert("Không thể áp dụng các thay đổi");
    }
  };

  const deleteUser = async (userId: string) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`${API_URL}/users/${userId}`, {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        });
        alert("User deleted successfully");
        fetchUsers();
      } catch (error) {
        console.error("Failed to delete user:", error);
        alert("Failed to delete user");
      }
    }
  };

  const toggleWaitlist = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/toggle-waitlist`,
        { is_enabled: !isWaitlistEnabled },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      if (response.data.success) {
        setIsWaitlistEnabled(!isWaitlistEnabled);
        fetchUserStats();
        alert("Waitlist status updated successfully");
      } else {
        alert("Failed to update waitlist status");
      }
    } catch (error) {
      console.error("Error toggling waitlist:", error);
      alert("Error toggling waitlist");
    }
  };

  const activateWaitlistUsers = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/activate-waitlist-users`,
        {},
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      if (response.data.success) {
        fetchUserStats();
        fetchUsers();
        alert("Activated 1000 waitlist users successfully");
      } else {
        alert("Failed to activate waitlist users");
      }
    } catch (error) {
      console.error("Error activating waitlist users:", error);
      alert("Error activating waitlist users");
    }
  };

  const blockUser = async (userId: string) => {
    try {
      await axios.put(
        `${API_URL}/users/${userId}`,
        { status: "Blocked" },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      alert("User blocked successfully");
      fetchUsers();
    } catch (error) {
      console.error("Failed to block user:", error);
      alert("Failed to block user");
    }
  };

  const unblockUser = async (userId: string) => {
    try {
      await axios.put(
        `${API_URL}/users/${userId}`,
        { status: "Active" },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      alert("User unblocked successfully");
      fetchUsers();
    } catch (error) {
      console.error("Failed to unblock user:", error);
      alert("Failed to unblock user");
    }
  };

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const updateMessageLimits = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/update-message-request-limit`,
        {
          level_0_limit: messageLimits.level_0,
          level_1_limit: messageLimits.level_1,
        },
        {
          headers: { Authorization: `Bearer ${auth?.user.access_token}` },
        }
      );
      if (response.data.success) {
        alert("Message limits updated successfully");
        fetchUsers();
      } else {
        alert("Failed to update message limits");
      }
    } catch (error) {
      console.error("Error updating message limits:", error);
      alert("Error updating message limits");
    }
  };

  const handleTempFilterChange = (filterType: keyof IFilters, value: any) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleActivityStartChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    const isoString = date ? date.toISOString() : "";
    handleTempFilterChange("activityStart", isoString);
  };

  const handleActivityEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    const isoString = date ? date.toISOString() : "";
    handleTempFilterChange("activityEnd", isoString);
  };

  const applyFilters = () => {
    setFilters(tempFilters);
    setCurrentPage(1); // Reset to first page when applying new filters
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--background-color-chat-container)",
        width: "100%",
        height: "100%",
        padding: "3px",
      }}
    >
      {/* Thêm nút Quay lại ở đầu trang */}
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          mb: 2,
          background: 'var(--Color-Style-Primary-Gradient-Primary)',
          '&:hover': {
            background: 'var(--Color-Style-Primary-Gradient-Primary-hover)',
          },
        }}
      >
        Quay lại Chatbot
      </Button>

      <Typography variant="h4" gutterBottom sx={{ color: "var(--text-color)" }}>
        User Management
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                User Statistics
              </Typography>
              {userStats && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      Total Users: {userStats.total_users}
                    </Typography>
                    <Typography>
                      Total Waitlist: {userStats.total_waitlist}
                    </Typography>
                    <Typography>
                      Total Online: {userStats.total_online}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Total Active: {userStats.total_active}
                    </Typography>
                    <Typography>
                      Total Blocked: {userStats.total_blocked}
                    </Typography>
                    <Typography>
                      New Registrations Today:{" "}
                      {userStats.new_registrations_today}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Message Limits
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Level 0 Limit"
                    type="number"
                    value={messageLimits.level_0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setMessageLimits({
                        ...messageLimits,
                        level_0: parseInt(e.target.value),
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Level 1 Limit"
                    type="number"
                    value={messageLimits.level_1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setMessageLimits({
                        ...messageLimits,
                        level_1: parseInt(e.target.value),
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateMessageLimits}
                    fullWidth
                  >
                    Apply Limits
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Waitlist Management
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleWaitlist}
                    fullWidth
                  >
                    {isWaitlistEnabled ? "Disable Waitlist" : "Enable Waitlist"}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={activateWaitlistUsers}
                    fullWidth
                  >
                    Activate 1000 Waitlist Users
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Filters
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Search users"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchTerm(e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="level-select-label">Level</InputLabel>
                    <Select
                      labelId="level-select-label"
                      multiple
                      value={tempFilters.level}
                      onChange={(e) =>
                        handleTempFilterChange(
                          "level",
                          e.target.value as number[]
                        )
                      }
                      input={<OutlinedInput label="Level" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {levelOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox
                            checked={
                              tempFilters.level.indexOf(option.value) > -1
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="permissions-select-label">
                      Permissions
                    </InputLabel>
                    <Select
                      labelId="permissions-select-label"
                      multiple
                      value={tempFilters.permissions}
                      onChange={(e) =>
                        handleTempFilterChange(
                          "permissions",
                          e.target.value as string[]
                        )
                      }
                      input={<OutlinedInput label="Permissions" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {availablePermissions.map((perm) => (
                        <MenuItem key={perm} value={perm}>
                          <Checkbox
                            checked={tempFilters.permissions.indexOf(perm) > -1}
                          />
                          <ListItemText primary={perm} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      multiple
                      value={tempFilters.status}
                      onChange={(e) =>
                        handleTempFilterChange(
                          "status",
                          e.target.value as string[]
                        )
                      }
                      input={<OutlinedInput label="Status" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox
                            checked={
                              tempFilters.status.indexOf(option.value) > -1
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="online-select-label">
                      Online Status
                    </InputLabel>
                    <Select
                      labelId="online-select-label"
                      value={tempFilters.online}
                      onChange={(e) =>
                        handleTempFilterChange("online", e.target.value)
                      }
                      input={<OutlinedInput label="Online Status" />}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="1">Online</MenuItem>
                      <MenuItem value="0">Offline</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Activity Start"
                    type="date"
                    value={
                      tempFilters.activityStart
                        ? new Date(tempFilters.activityStart)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={handleActivityStartChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Activity End"
                    type="date"
                    value={
                      tempFilters.activityEnd
                        ? new Date(tempFilters.activityEnd)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={handleActivityEndChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={applyFilters}
                    fullWidth
                  >
                    Apply Filters
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell onClick={() => handleSort("id")}>
                        ID
                        <Tooltip title="Sort by ID">
                          <IconButton size="small">
                            {sortField === "id" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort("full_name")}>
                        Name
                        <Tooltip title="Sort by Name">
                          <IconButton size="small">
                            {sortField === "full_name" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort("email")}>
                        Email
                        <Tooltip title="Sort by Email">
                          <IconButton size="small">
                            {sortField === "email" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell onClick={() => handleSort("is_online")}>
                        Online
                        <Tooltip title="Sort by Online Status">
                          <IconButton size="small">
                            {sortField === "is_online" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => handleSort("last_active")}
                      >
                        Activity
                        <Tooltip title="Sort by Last Activity">
                          <IconButton size="small">
                            {sortField === "last_active" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>Message Limit</StyledTableCell>
                      <StyledTableCell
                        onClick={() => handleSort("message_total")}
                      >
                        Message Total
                        <Tooltip title="Sort by Message Total">
                          <IconButton size="small">
                            {sortField === "message_total" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => handleSort("message_count_today")}
                      >
                        Message Today
                        <Tooltip title="Sort by Messages Today">
                          <IconButton size="small">
                            {sortField === "message_count_today" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort("level")}>
                        Level
                        <Tooltip title="Sort by Level">
                          <IconButton size="small">
                            {sortField === "level" &&
                              (sortOrder === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              ))}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>Permissions</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <StyledTableCell>{user.id}</StyledTableCell>
                        <StyledTableCell>{user.full_name}</StyledTableCell>
                        <StyledTableCell>{user.email}</StyledTableCell>
                        <StyledTableCell>{user.phone_number}</StyledTableCell>
                        <StyledTableCell>
                          <Select
                            value={changes[user.id]?.status ?? user.status}
                            onChange={(e) => {
                              updateUser(
                                user.id,
                                "status",
                                e.target.value as string
                              );
                            }}
                          >
                            <MenuItem value="Waitlist">Waitlist</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Blocked">Blocked</MenuItem>
                          </Select>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: user.is_online
                                ? "green"
                                : "gray",
                            }}
                          ></span>
                        </StyledTableCell>
                        <StyledTableCell>
                          {user.last_active
                            ? new Date(user.last_active).toLocaleString()
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            type="number"
                            value={
                              changes[user.id]?.message_limit ??
                              user.message_limit
                            }
                            onChange={(e) =>
                              updateUser(
                                user.id,
                                "message_limit",
                                e.target.value
                              )
                            }
                            style={{ width: "60px" }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{user.message_total}</StyledTableCell>
                        <StyledTableCell>
                          {user.message_count_today}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Select
                            value={changes[user.id]?.level ?? user.level}
                            onChange={(e) =>
                              updateUser(
                                user.id,
                                "level",
                                e.target.value as string
                              )
                            }
                          >
                            {levelOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </StyledTableCell>
                        <StyledTableCell>
                          {availablePermissions.map((permission) => (
                            <FormControlLabel
                              key={permission}
                              control={
                                <Checkbox
                                  checked={
                                    changes[user.id]?.permissions?.[
                                      permission
                                    ] ?? user.permissions[permission]
                                  }
                                  onChange={(e) =>
                                    updatePermission(
                                      user.id,
                                      permission,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={permission}
                            />
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => applyChanges()}
                            sx={{ mr: 1 }}
                          >
                            Apply
                          </Button>
                          {user.status !== "Blocked" ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => blockUser(user.id)}
                              sx={{ mr: 1 }}
                            >
                              Block
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => unblockUser(user.id)}
                              sx={{ mr: 1 }}
                            >
                              Unblock
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => deleteUser(user.id)}
                          >
                            Delete
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                />
              </Box>

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applyChanges}
                  fullWidth
                >
                  Apply All Changes
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserManagement;