// /root/Viet/Frontend/src/contexts/AuthContext.tsx

import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs'; // Import qs để serialize form data
import { API_URL } from '../api/client';

interface ILoginResponse {
  access_token: string;
  user_id: string;
  // Thêm các trường khác nếu API trả về
}

interface IAuthProviderProps {
  children: ReactNode;
}

export interface AuthContextType {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  login: (credentials: {
    username: string;
    password: string;
    rememberMe: boolean;
    recaptchaToken: string | null;
    grantType?: string;
    scope?: string;
    clientId?: string;
    clientSecret?: string;
  }) => Promise<ILoginResponse>;
  loginWithGoogle: (credential: string) => Promise<any>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  const login = async (
    credentials: {
      username: string;
      password: string;
      rememberMe: boolean;
      recaptchaToken: string | null;
      grantType?: string;
      scope?: string;
      clientId?: string;
      clientSecret?: string;
    }
  ): Promise<ILoginResponse> => {
    try {
      const {
        username,
        password,
        recaptchaToken,
        grantType = 'password',
        scope = '',
        clientId = '',
        clientSecret = '',
      } = credentials;

      const formData = qs.stringify({
        username,
        password,
        recaptcha_token: recaptchaToken || 'string',
        grant_type: grantType,
        scope,
        client_id: clientId,
        client_secret: clientSecret,
      });

      const response = await axios.post<ILoginResponse>(
        `${API_URL}/login`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const user = response.data;
      return user;
    } catch (error: any) {
      console.error(
        'Login failed:',
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  const loginWithGoogle = async (credential: string): Promise<any> => {
    try {
      const response = await axios.post(`${API_URL}/google-login`, {
        token: credential,
      });
      const userData = response.data;
      if (userData && userData.access_token) {
        return userData;
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error: any) {
      console.error('Google login failed:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user-id');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('theme');
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      value={{ user, setUser, login, loginWithGoogle, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
