import React, { useEffect } from 'react';
import SuggestionButton from './SuggestionButton'; // Đảm bảo bạn đã định nghĩa SuggestionButton

interface SuggestionsProps {
  suggestions: string[];
  onSuggestionClick: (suggestion: string) => void;
}

const Suggestions: React.FC<SuggestionsProps> = ({ suggestions, onSuggestionClick }) => {
  useEffect(() => {
    console.log(suggestions);
  }, [suggestions]);

  return (
    <div>
      {suggestions.map((suggestion, index) => (
        <SuggestionButton
          key={index}
          onClick={() => onSuggestionClick(suggestion)}
        >
          {suggestion}
        </SuggestionButton>
      ))}
    </div>
  );
};

export default Suggestions;