import styled, { createGlobalStyle } from 'styled-components';

import {
  Button,
  TextField,
  Card,
  Select,
  Container as MUIContainer,
} from '@mui/material';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
  
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
`;

export const Logo = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderButton = styled(Button)`
  margin-left: 20px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface LeftSidebarProps {
  width: number;
}

export const LeftSidebar = styled.div<LeftSidebarProps>`
  width: ${(props) => props.width}px;
  background-color: #f0f0f0;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  resize: horizontal;
  @media (max-width: 768px) {
    width: 100%;
    resize: none;
  }
`;

export const ChatHistory = styled.div`
  padding: 10px;
`;

interface ChatHistoryItemProps {
  isActive: boolean;
}

export const ChatHistoryItem = styled.div<ChatHistoryItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#e0e0e0' : 'transparent')};
  border-radius: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d0d0d0;
  }
`;

export const ChatHistoryItemContent = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 0.9em;
`;

export const HideButton = styled(Button)`
  display: none;
  ${ChatHistoryItem}:hover & {
    display: inline;
  }
`;

export const DateHeader = styled.div`
  font-weight: bold;
  padding: 10px 5px;
  background-color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
`;

export const NewChatButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

interface ChatContainerProps {
  isRightSidebarOpen: boolean;
  rightSidebarWidth: number;
}

export const ChatContainer = styled.div<ChatContainerProps>`
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.isRightSidebarOpen
      ? `calc(100% - ${props.rightSidebarWidth}px)`
      : '100%'};
  transition: width 0.3s ease-in-out;
  background-color: #234234;
`;

export const SessionId = styled.div`
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
`;

export const ChatMessages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MessageFrame = styled.div`
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  max-width: 70%;
`;

export const UserMessage = styled(MessageFrame)`
  align-self: flex-end;
  background-color: transparent;
`;

export const BotMessage = styled(MessageFrame)`
  align-self: flex-start;
  background-color: transparent;
  font-size: 0.9em;
  max-width: 80%;
  word-wrap: break-word;
`;

export const TypewriterText = styled.div`
  white-space: pre-wrap;
`;

export const RateButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
`;

export const RateButton = styled(Button)`
  margin-right: 5px;
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 20px;
`;

export const StyledInput = styled(TextField)`
  flex-grow: 1;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

export const SendButton = styled(Button)`
  margin-left: 10px;
`;

export const Suggestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`;

export const SuggestionButton = styled(Button)`
  margin: 0 5px 5px 0;
`;
interface RightSidebarIconProps {
  isActive: boolean;
}

export const RightSidebarIcon = styled.div<RightSidebarIconProps>`
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#ccc' : 'transparent')};
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#bbb' : '#ddd')};
  }
`;

interface RightSidebarProps {
  isOpen: boolean;
  width: number;
}

export const RightSidebar = styled.div<RightSidebarProps>`
  width: ${(props) => (props.isOpen ? props.width : 0)}px;
  background-color: #f0f0f0;
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
  @media (max-width: 768px) {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${(props) => (props.isOpen ? '100%' : 0)};
    z-index: 1000;
  }
`;

export const Container = styled(MUIContainer)`
`;

export const Title = styled.h2`
  margin-bottom: 20px;
`;

export const MessageLimitsContainer = styled.div`
  margin-bottom: 20px;
`;

export const LimitInput = styled(TextField)`
  width: 100px;
  margin-right: 10px;
`;

export const StatsCard = styled(Card)`
  margin-bottom: 20px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

export const StyledSelect = styled(Select)`
  width: 150px;
`;

export const DeleteButton = styled(Button)`
  background-color: #f44336;
  color: white;
  &:hover {
    background-color: #d32f2f;
  }
`;
