import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../config';
import FormContainer from 'components/Form/FormContainer';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import ReturnButton from 'components/ReturnButton';
import { useLanguage } from 'contexts/LanguageContext';
import { labels } from './languageLabels';
import { resetPassword, sendResetPasswordRequest } from 'services/api';
import { useNavigate } from 'react-router-dom';
import SendEmailSuccess from './SendEmailSuccess';

type FormValues = {
  [key: string]: string;
};

function ResetPassword() {
  const { language } = useLanguage();
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [errors, setErrors] = useState<FormValues>({});
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();

  const currentLabels = labels[language] || labels['en'];

  const handleRecaptchaChange = (token: string | null) => setRecaptchaToken(token);

  const handleSendToken = async () => {
    if (!recaptchaToken) {
      alert(currentLabels.completeRecaptcha);
      return;
    }
    setLoading(true);
    try {
      if (step === 1) {
        const response = await sendResetPasswordRequest(email, recaptchaToken);
        if (response) {
          setShowSuccessDialog(true);
          setTimeout(() => {
            setShowSuccessDialog(false);
            setStep(2);
          }, 2000);
        }
      } else if (step === 2) {
        const response = await resetPassword(token, newPassword, recaptchaToken);
        if (response) {
          setStep(3);
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        }
      }
    } catch (error) {
      alert(`${currentLabels.registrationFailed}: ${error}`);
      recaptchaRef.current?.reset();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step === 3) {
      const timer = setTimeout(() => navigate('/login'), 2000);
      return () => clearTimeout(timer);
    }
  }, [step, navigate]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} ${currentLabels.isRequired}`,
      }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const { name, value } = e.target;
    setValue(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? '' : `${name.charAt(0).toUpperCase() + name.slice(1)} ${currentLabels.isRequired}`,
    }));
  };

  return (
    <FormContainer>
      <Container className="reset-password-container">
        <Box className="reset-password-form">
          <ReturnButton style={{ position: 'absolute' }} />
          <Typography
            sx={{
              color: 'var(--Button-button-label-primary)',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '32px',
              fontWeight: 600,
              letterSpacing: '2px',
            }}
          >
            {currentLabels.resetPassword}
          </Typography>
          {step === 1 && (
            <Box sx={{ mt: 2 }}>
              <Typography>{currentLabels.email}</Typography>
              <TextField
                 
                margin="normal"
                
                required
                fullWidth
                name="email"
                id="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setEmail)}
                onBlur={handleBlur}
                error={!!errors.email}
                helperText={errors.email}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'var(--primary-text-color)', /* Màu của label khi không có giá trị nhập vào */
                  },
                  '& .MuiInputBase-root': {
                    backgroundColor: 'transparent !important',
                    borderRadius: '8px',
                    color: 'var(--primary-text-color) !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--General-Stroke) !important',
                    '&:hover': {
                      borderColor: 'var(--General-Stroke) !important',
                    },
                    '&:focus-within': {
                      borderColor: 'var(--General-Stroke) !important',
                    },
  
                  },
                }}
              />
              <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptchaChange} />
              <Button
                fullWidth
                variant="contained"
                onClick={handleSendToken}
                sx={{
                  mt: 2,
                  borderRadius: '8px',
                  background: 'var(--Color-Style-Primary-Gradient-Primary)',
                  height: '55px',
                  color: 'var(--Button-button-label-primary)',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 500,
                  '&:hover': {
                    background: 'var(--Color-Style-Primary-Gradient-Primary-hover)',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : currentLabels.send}
              </Button>
            </Box>
          )}
          {step === 2 && (
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="token"
                label={currentLabels.resetToken}
                name="token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={currentLabels.newPassword}
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptchaChange} />
              <Button
                fullWidth
                variant="contained"
                onClick={handleSendToken}
                sx={{
                  mt: 3,
                  mb: 2,
                  background: 'var(--Color-Style-Primary-Gradient-Primary)',
                  color: 'var(--Button-button-label-primary)',
                  borderRadius: '8px',
                  '&:hover': {
                    background: 'var(--Color-Style-Primary-Gradient-Primary-hover)',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : currentLabels.resetPassword}
              </Button>
            </Box>
          )}
          {step === 3 && (
            <Typography sx={{ mt: 2 }}>
              {currentLabels.resetSuccess}
            </Typography>
          )}
        </Box>
      </Container>

      {/* Success Dialog */}
      {showSuccessDialog && <SendEmailSuccess />}
    </FormContainer>
  );
}

export default ResetPassword;
